@font-face {
  font-family: 'lunchtype22regular';
  src: url('./Lunchtype22/lunchtype22-regular-webfont.eot'),
    url('./Lunchtype22/lunchtype22-regular-webfont.woff2') format('woff2'),
    url('./Lunchtype22/lunchtype22-regular-webfont.woff') format('woff'),
    url('./Lunchtype22/lunchtype22-regular-webfont.ttf') format('truetype'),
    url('./Lunchtype22/lunchtype22-regular-webfont.svg#lunchtype22regular') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'lunchtype22regular';
  src: url('./Lunchtype22/lunchtype22-medium-webfont.eot');
  src: url('./Lunchtype22/lunchtype22-medium-webfont.eot?#iefix') format('embedded-opentype'),
    url('./Lunchtype22/lunchtype22-medium-webfont.woff2') format('woff2'),
    url('./Lunchtype22/lunchtype22-medium-webfont.woff') format('woff'),
    url('./Lunchtype22/lunchtype22-medium-webfont.ttf') format('truetype'),
    url('./Lunchtype22/lunchtype22-medium-webfont.svg#lunchtype22medium') format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'lunchtype22regular';
  src: url('./Lunchtype22/lunchtype22-light-webfont.eot');
  src: url('./Lunchtype22/lunchtype22-light-webfont.eot?#iefix') format('embedded-opentype'),
    url('./Lunchtype22/lunchtype22-light-webfont.woff2') format('woff2'),
    url('./Lunchtype22/lunchtype22-light-webfont.woff') format('woff'),
    url('./Lunchtype22/lunchtype22-light-webfont.ttf') format('truetype'),
    url('./Lunchtype22/lunchtype22-light-webfont.svg#lunchtype22light') format('svg');
  font-weight: 100;
  font-style: normal;
}
