/// Headers Styles

.header-container {
  @extend .col-12;
  @extend .border-bottom;
  @extend .no-gutters;
  @extend .row;
  @extend .py-4_5;
  @extend .px-5;
  @extend .mr-auto;
  h2 {
    @extend .my-2;
  }
  h1 {
    @extend .my-2;
  }
}

.header-icon {
  @extend .width-rem-5;
}

.header-attached-links-container {
  width: 28rem;
  border-left: none;
  .text-on-border {
    display: none;
  }
  @extend .py-3;
  @extend .px-3;
  @extend .d-flex;
  @extend .flex-column;
  @extend .justify-content-between;
}

.header-main-content-container {
  @extend .no-gutters;
  @extend .row;
  @extend .py-4_5;
  @extend .px-4;
  h2 {
    @extend .my-2;
  }
  h1 {
    @extend .my-2;
  }
}

.dashboard-card-header {
  width: 100%;
  align-items: center;
  padding: 1rem 1.5rem 1rem 1.5rem !important;
  h3 {
    font-size: 1.4rem;
  }
  hr {
    padding: 0;
  }
}

.card-header-low-border {
  @extend .dashboard-card-header;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.header-t1 {
  @include base-header;
}

.header-t2 {
  @include base-header;
  font-size: 1.125rem !important;
  margin-bottom: 0;
}

.header-t3 {
  @include base-header;
  max-width: 9rem;
}

.header-t4 {
  @include base-header;
  max-width: 11rem;
}

.replica-header {
  @include base-header;
  max-width: 100%;
  font-size: 1.4rem !important;
}

// Table Headers

.entity-block-table-header {
  display: flex;
  padding: 10px;
  border: 1px solid $gray-300;
  background-color: $white;
  border-radius: $border-radius;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom: 0;
  flex-direction: column;

  & > .btn {
    max-width: 100%;
    min-width: 150px;
    margin-top: 8px;
    order: 2;
  }

  .input-group {
    max-width: 100%;
    input {
      border-radius: 0.2rem !important;
    }
  }

  .input-group > .form-control {
    z-index: 0;
  }

  .select-container {
    margin-top: 8px;
    width: 100%;
    order: 1;
    .btn {
      width: 100%;
      order: 1;
    }
  }
}

.entity-block-table-header-devices {
  display: flex;
  padding: 10px;
  border: 1px solid $gray-300;
  background-color: $white;
  border-radius: $border-radius;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom: 0;
  flex-direction: column;

  .devices-main-buttons {
    display: flex;
    flex-direction: column;
    .btn {
      min-width: 100%;
      margin-top: 3px;
      margin-right: 0 !important;
    }
  }

  .input-group-devices {
    max-width: 100%;
  }
  .entity-block-table-header-devices-search-orgs {
    flex-direction: column;
    & > div {
      .btn {
        margin-top: 8px;
        width: 100%;
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .entity-block-table-header {
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    .select-container {
      margin-top: 0;
      max-width: 200px;
      .btn {
        margin-top: 0;
        max-width: 150px;
      }
    }

    & > .btn {
      max-width: 300px;
      margin-top: 0;
      margin-left: 1rem;
    }

    .input-group {
      min-width: 150px;
      max-width: 200px;
      margin-right: auto;
    }
  }
  .entity-block-table-header-devices {
    flex-direction: column;
  }
}

@include media-breakpoint-up(md) {
  .entity-block-table-header-devices {
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    .devices-main-buttons {
      display: flex;
      flex-direction: row;
      .btn {
        max-width: 100%;
        min-width: 150px;
        margin-top: 0;
        margin-left: 1rem;
      }
    }

    .input-group-devices {
      min-width: 80px;
      max-width: 200px;
      margin-right: auto;
    }
    .entity-block-table-header-devices-search-orgs {
      flex-direction: row;
      & > div {
        .btn {
          margin-top: 0;
          margin-left: 0.5rem;
          @include base-header;
          max-width: 15rem;
        }
      }
    }
  }

  .table-header-devices-early-column {
    align-items: stretch;
    flex-direction: column;

    .devices-main-buttons {
      flex-direction: column;
      .btn {
        min-width: 100%;
        margin-top: 8px;
        margin-left: 0;
      }
    }
    .input-group-devices {
      min-width: 100%;
    }
    .entity-block-table-header-devices-search-orgs {
      flex-direction: column;
      & > div {
        .btn {
          min-width: 100%;
          margin-top: 8px;
          margin-left: 0;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .table-header-devices-early-column {
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    .devices-main-buttons {
      display: flex;
      flex-direction: row;
      .btn {
        max-width: 100%;
        min-width: 120px;
        margin-top: 0;
        margin-left: 1rem;
      }
    }

    .input-group-devices {
      min-width: 80px;
      max-width: 200px;
      margin-right: auto;
    }
    .entity-block-table-header-devices-search-orgs {
      flex-direction: row;
      & > div {
        & > .btn {
          margin-top: 0px;
          margin-left: 0.5rem;
          @include base-header;
          max-width: 9rem;
        }
      }
    }
  }

  .header-attached-links-container {
    border-left: 1px solid rgba(0, 0, 0, 0.08);
    .text-on-border {
      display: block;
      padding-top: 5px;
      height: 40px;
      width: 40px;
      position: absolute;
      left: -0.8rem;
      top: 45%;
      font-size: 1.4rem;
      background: #fafafa;
      span {
        opacity: 0.5;
      }
    }
  }
}

@keyframes custom-fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.overlay-show {
  z-index: 10;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
  animation-name: custom-fadein;
  animation-duration: 0.3s;
  backdrop-filter: blur(2px);
}
