/////////// Light theme ///////////

body.light-theme {
  background-color: $light;

  .main-header {
    .navbar-nav {
      li {
        a {
          color: $gray-900;
        }
      }
    }
  }

  .sidebar {
    background-color: #3b3b44;

    .sidebar-header {
      background-color: #3b3b44;
    }

    ul.navbar-nav {
      li {
        background-color: #3b3b44;
        color: #fafafa;

        &.heading {
          background: linear-gradient(90deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
          color: #999;
        }
        &.credits-item {
          background: rgba(255, 255, 255, 0.1);
          color: #999;
        }
        &.dark {
          background: linear-gradient(90deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
          color: #999;
        }

        a {
          color: $gray-300;

          &:hover {
            color: $white;
          }

          &.active {
            background: linear-gradient(90deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%);
            color: #fff;
          }
        }

        .submenu {
          background-color: $gray-900;

          .nav-item {
            background-color: transparent;
          }
        }

        .submenu-link {
          &.open {
            background-color: $extra-dark;
          }
        }
      }
    }
  }

  .cockpit {
    background: $white;
  }

  @media (min-width: 576px) {
    .sidebar {
      @include customstyled-scrollbar-withhover;
    }
  }
}
