//////// Sidebar///////

.sidebar {
  position: fixed;

  .sidebar-header {
    .navbar-brand {
      margin-right: 0;

      img {
        min-width: 30px;
        vertical-align: baseline;
        margin-left: 30px;
      }
      span {
        margin-left: 15px;
        color: #fff;
      }
    }
    #theme-toggler,
    #updates-widget {
      margin-right: 15px;
      background-color: rgba(255, 255, 255, 0.08);
      border: none;
    }
  }

  .navbar-nav {
    li {
      padding-left: 0;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 1.2rem;
      }

      &.heading {
        text-transform: uppercase;
        padding: 8px 0 8px 20px !important;
        font-size: 12px;
        margin: 8px 0 !important;
      }

      &.credits-item {
        padding: 8px 0 8px 20px !important;
        margin: 8px 0 !important;
      }

      &.credits-item .credits-item-icon-div {
        display: inline-block;
        padding-right: 0.7rem;
        padding-top: 0.3rem;
        vertical-align: top;
      }

      &.credits-item .credits-item-credits-span {
        font-size: 15px;
        font-weight: bolder;
        color: #fff;
      }

      &.credits-item .credits-item-days-span {
        font-size: 13px;
        color: #eee;
      }

      &.credits-item .credits-item-upgrade-button {
        float: right;
        padding-top: 0.3rem;
        padding-right: 1.2rem;
      }

      &.credits-item i {
        color: #ffc107;
        font-size: 15px;
      }

      a {
        padding: 10px 20px 10px 24px !important;
      }
    }
  }
}

// Media query for up 992px screens
@include media-breakpoint-up(md) {
  .sidebar {
    .sidebar-header {
      .navbar-brand {
        img {
          min-width: 35px;
          margin-left: 12px;
        }
      }
    }

    .navbar-nav {
      li {
        &.heading {
          padding: 10px 0 10px 20px;
          font-size: 12px;
        }

        &.credits-item {
          padding: 10px 0 10px 20px;
          font-size: 12px;
        }

        a {
          padding: 1rem 20px;
          text-align: left;
        }
      }
    }
  }
}

#HW_badge_cont {
  height: 0;
  width: 0;
}

#HW_badge {
  display: none !important;
}

#HW_frame_cont {
  margin-top: 2rem;
  left: 2rem !important;
}
