.tenants-connection {
  .card-item-wrp {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .item {
      flex-grow: 1;
      padding: 6px;
      justify-content: center;
      display: flex;
      align-items: baseline;
      width: 33%;

      i {
        margin-right: 5px;
        font-size: 11px;
      }

      span {
        font-weight: bold;
      }
    }
  }
}
