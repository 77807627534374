/////////// Modals ///////////

.modal {
  .modal-content {
    .modal-header {
      background-color: $gray-200;
      display: block;

      h5 {
        text-align: center;
        font-weight: bold;
      }
    }

    .r-side {
      padding-top: 0;
    }
  }

  button.close {
    position: absolute;
    top: 15px;
    right: 20px;
  }

  .modal-footer,
  .form-footer {
    display: flex;
    border-top: none;
    justify-content: space-between;
    padding-top: $md-padding;

    .btn-wrp {
      margin-left: auto;
      .btn {
        margin-right: 8px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .modal-footer {
    padding: 0 1.5rem 1.5rem;
    border-top: none;
  }
}

.modal-dialog {
  @extend .modal-dialog-centered;
  overflow: visible;
}

.close {
  opacity: 1;
}

.wider-modal {
  width: 640px !important;
  max-width: none !important;
}

.extra-wide-modal {
  width: 980px !important;
  max-width: none !important;
}

.wider-modal-2 {
  max-width: 640px;
  .devices-row {
    display: flex;
    flex-direction: column;
    & > div {
      max-width: none !important;
      min-height: 250px;
      max-height: 38vh;
    }
  }

  .clouds-config-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    & > div {
      min-width: 11rem;
    }
  }
}

.large-modal {
  width: 860px !important;
  max-width: none !important;
}

@include media-breakpoint-up(sm) {
  .wider-modal-2 {
    .devices-row {
      display: flex;
      flex-direction: row;
      & > div {
        min-width: 20rem;
        max-height: 42vh;
      }
    }
  }
}

//////// Modal window styles///////

.modal-base-box {
  height: 148px;
  overflow-y: scroll;
  padding: 1rem;
  border: $border-light;
}

.modal-header {
  font-size: 1rem;
  border: none;
  &__small {
    font-size: 12px;
    padding-left: 1rem;
    background: #fafafa;
  }
}

.modal-large-box {
  height: 238px;
  border: $border-light;
  padding-top: 1rem;
  overflow-y: scroll;
  margin-bottom: 1rem;
  &__input {
    padding-left: 1rem;
  }
}

.quick-search-modal-small-box {
  max-height: 274px;
  border: $border-light;
  overflow-y: scroll;
  margin-bottom: 1rem;
  transform: translate3d(0px, -4px, 0px) !important;
}

.devices-multitenancy-box {
  max-height: 200px;
  overflow-y: auto;
  margin-bottom: 1rem;
}

.quick-search-main-box {
  position: absolute;
  width: 100%;
}

.quick-search-explain {
  border: 1px solid white;
  background: $white;
  margin-left: 1.15rem;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  width: calc(100% - 2.5rem);
}

.quick-search-result {
  position: static !important;
  border: 1px solid white;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-left: 1.15rem;
  width: calc(100% - 2.5rem);
}

.modal-width-680 {
  max-width: 680px;
  transform: translate3d(0px, -3.533px, 0px) !important;
}

.quick-search-triangle {
  margin-left: 40px;
  width: 0;
  height: 0;
  border: 9px solid transparent;
  border-top: 0;
  border-bottom: 8px solid white;
}

.quick-search-categories {
  margin-left: 1.15rem;
  background: #fff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  z-index: 99999;
  padding: 0px 10px;
  width: calc(100% - 2.5rem);
}

.quick-search-type {
  border-bottom: 2px solid $white;
}

.quick-search-type-underline {
  border-bottom: 2px solid $blue;
  color: $blue;
}
