@mixin stripes($color: $white) {
  background: repeating-linear-gradient(-45deg, $color, $color 3px, darken($color, 3%) 3px, darken($color, 3%) 6px);
}

@mixin placeholder {
  ::-webkit-input-placeholder {
    @content;
  }
  :-moz-placeholder {
    @content;
  }
  ::-moz-placeholder {
    @content;
  }
  :-ms-input-placeholder {
    @content;
  }
}

@mixin base-header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden !important;
  max-width: 15rem;
}

@mixin fixed-column {
  background-color: #fff;
  position: sticky;
  position: -webkit-sticky;
  z-index: 1;
}

@mixin customstyled-scrollbar {
  /* total width */
  &::-webkit-scrollbar {
    background: transparent;
    width: 14px;
    display: block;
  }

  /* background of the scrollbar except button or resizer */
  &::-webkit-scrollbar-track-piece {
    display: none;
  }

  /* scrollbar itself */
  &::-webkit-scrollbar-thumb {
    background-color: rgba(186, 186, 192, 0.4);
    border-radius: 16px;
    background-clip: padding-box;
    border: 3px solid rgba(0, 0, 0, 0);
  }

  /* set button(top and bottom of the scrollbar) */
  &::-webkit-scrollbar-button {
    display: none;
  }
}

@mixin customstyled-scrollbar-withhover {
  /* total width */
  &::-webkit-scrollbar {
    display: none;
  }

  /* background of the scrollbar except button or resizer */
  &::-webkit-scrollbar-track-piece {
    display: none;
  }

  /* scrollbar itself */
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 16px;
    background-clip: padding-box;
    border: none;
  }

  /* set button(top and bottom of the scrollbar) */
  &::-webkit-scrollbar-button {
    display: none;
  }

  &:hover {
    /* scrollbar itself */
    &::-webkit-scrollbar-thumb {
      background-color: rgba(186, 186, 192, 0.4);
      border-radius: 16px;
      border: 3px solid rgba(0, 0, 0, 0);
    }
    /* total width */
    &::-webkit-scrollbar {
      background: transparent;
      width: 14px;
      display: block;
    }
  }
}

@mixin flex-node-component($color1: #fff, $color2: #333) {
  display: flex;
  flex-wrap: wrap;
  background: $color1;
  border: 1px solid $color2;
  border-radius: 4px;
  & > div:first-child {
    border-right: none;
    border-bottom: 1px solid $color2;
  }
  div {
    padding: 0.8rem;
  }
  .orand-connector {
    display: none;
    margin-top: 2.5rem;
  }
}
