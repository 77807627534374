.user-panel-container {
  background-color: $white;
  width: 100%;
  box-shadow: 0 1px #e5e5e5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  padding-left: 24px;
  &__block {
    max-width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      margin: 0 0 0 10px;
      font-family: $font-family-sans-serif;
      font-weight: bold;
    }
  }
  h1 {
    font-size: 20px;
    padding-left: 10px;
    margin-right: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  i {
    color: #777e90;
    font-size: 1.2em;
  }
}

.user-panel-square {
  height: 38px;
  width: 38px;
  padding-right: 0;
  border-top: none;
  border-bottom: none;
  border-right: 1px solid #e5e5e5;
  border-left: none;
  border-radius: 0;
  i {
    color: #777e90;
    font-size: 1em;
    opacity: 0.5;
  }
  &:hover {
    background: #fafafa;
    border-right: 1px solid #e5e5e5;
    i {
      opacity: 0.7;
    }
  }
}

.user-panel-name-container {
  display: flex;
  min-width: 40px;
  padding-left: 5px;
  &:hover {
    cursor: pointer;
  }
  p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.profile-pic-container {
  height: 38px;
  width: 38px;
  background-size: 60%;
  padding: 8px;
  border-radius: 4px;
  &:hover {
    cursor: pointer;
  }
  img {
    height: 26px;
    width: 26px;
    object-fit: cover;
    border-radius: 4px;
  }
}

.user-panel-dropdown {
  height: 10px;
  width: 10px;
  margin: -4px 10px 0 10px;
}

.uspan-header-icon {
  display: none;
}

.user-panel-upgrade {
  display: none;
}

.user-panel-credits-container {
  display: none;
}

.user-panel-account-container {
  display: flex;
  align-items: center;
  max-width: 40%;
  &:hover {
    cursor: pointer;
    background: #fafafa;
  }
}

.user-panel-account-menu {
  margin-top: 1.5rem;
}

.user-panel-red-dot {
  height: 11px;
  width: 11px;
  border-radius: 7px;
  border: 2px solid #fff;
  background: $danger;
  position: absolute;
  right: 0.6rem;
  top: 1.6rem;
}

@include media-breakpoint-up(sm) {
  .user-panel-square {
    height: 60px;
    width: 60px;
    i {
      font-size: 1.3em;
    }
  }

  .profile-pic-container {
    height: 60px;
    width: 50px;
    padding: 10px;
    img {
      height: 40px;
      width: 40px;
    }
  }

  .user-panel-upgrade {
    display: flex;
    height: 60px;
    min-width: 100px;
    padding: 0 10px;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #e5e5e5;
    button {
      margin-left: 0 !important;
    }
  }

  .user-panel-credits-container {
    display: flex;
    height: 60px;
    min-width: 80px;
    border-right: 1px solid #e5e5e5;
    color: #777e90;
    font-size: 1.1em;
    justify-content: center;
    align-items: center;
    padding: 0 7px;
    span {
      opacity: 0.5;
    }
    img {
      opacity: 0.5;
      margin-right: 7px;
    }
    &:hover {
      background: #fafafa;
      border-right: 1px solid #e5e5e5;
      cursor: pointer;
      span {
        opacity: 0.7;
      }
      img {
        opacity: 0.7;
      }
    }
  }

  .user-panel-account-menu {
    margin-top: 32px;
  }

  .user-panel-account-container {
    max-width: 60%;
  }

  .user-panel-red-dot {
    right: 1.4rem;
    top: 2.4rem;
  }
}

@include media-breakpoint-up(lg) {
  .uspan-header-icon {
    display: block;
  }
}
