html {
  font-size: 13px;
}

.night-mode {
  background-color: #1d1d1d !important;
}

.night-mode .push-notification,
.night-mode .ips-example-card {
  filter: invert(0.84) brightness(2) contrast(1.5) hue-rotate(180deg) saturate(1.1);
}

.night-mode .user-panel-upgrade button {
  background: #fff3cd !important;
  filter: invert(0.84) brightness(2) contrast(1.5) hue-rotate(180deg) saturate(1.1);
}

.night-mode .btn-yellow-light {
  background: #fff3cd !important;
  filter: invert(0.84) brightness(2) contrast(1.5) hue-rotate(180deg) saturate(1.1);

  &:hover,
  &:active,
  &:focus {
    background-color: lighten(#fff3cd, 5%) !important;
    border-color: transparent;
  }
}

.night-mode .dedicated,
.night-mode .popover,
.night-mode .auth-block,
.night-mode .content-wrapper,
.night-mode .user-panel-container,
.night-mode .modal-dialog,
.night-mode .react-select-portaled__menu {
  filter: invert(0.84) brightness(1.25) contrast(1.18) hue-rotate(180deg) saturate(1.1);
}

.night-mode .img-night {
  filter: none;
}

.night-mode img,
.night-mode svg,
.night-mode .video-player,
.night-mode .ava-background-wrapper {
  filter: invert(0.8) brightness(1.5) contrast(0.83) hue-rotate(180deg);
}

.night-mode .sidebar {
  filter: brightness(0.9) contrast(1.4);
}

.night-mode .sidebar-header img {
  filter: none !important;
}

#root {
  height: 100vh;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &:focus {
    outline: none;
  }
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(../../images/chevron-down-solid-grey.svg) no-repeat;
  background-size: 12px;
  background-position: calc(100% - 20px) center;
}

button {
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

h1 {
  font-size: 22px;
  margin-bottom: 0;
  font-weight: bold;
  padding-left: 1.5rem;
}

h3 {
  font-weight: bold;
}

h4 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0;
}

h5 {
  font-size: 1.2rem;
}

a {
  cursor: pointer;
}

hr {
  width: 100%;
}

canvas {
  margin: 0 auto;
}

.bold {
  font-weight: bold;
}

.font-normal {
  font-weight: normal !important;
}

.font-gray {
  color: $gray-400;
}

.font-blue {
  color: $blue-dark;
}

.font-red {
  color: #f14545;
}

.font-warning {
  color: $warning;
}

.white-space-nowrap {
  white-space: nowrap;
}

.white-space-pre-wrap {
  white-space: pre-wrap;
}

.on {
  color: $success;
}

.off {
  color: $danger;
}

.not-sure {
  color: $warning;
}

.small {
  font-size: 90%;
}

.flex-group {
  display: flex;
  flex-wrap: wrap;

  & > div {
    width: 100%;
  }
}

.font-size-08 {
  font-size: 0.8em !important;
}

.font-size-md {
  font-size: 14px;
}

.font-size-sm {
  font-size: 12px;
}

.font-size-xs {
  font-size: 10px;
}

.font-size-lg {
  font-size: 16px;
}

.font-size-lg-plus {
  font-size: 20px;
}

.font-size-xl {
  font-size: 24px;
}

.font-size-xxl {
  font-size: 28px;
}

.font-size-xxxl {
  font-size: 32px;
}

.font-size-base {
  font-size: 1rem !important;
}

.container-fluid {
  height: 100%;
}

.text-ellipsis {
  text-overflow: ellipsis;
}

//////////// Padding //////////

.fix-up-to-header {
  position: sticky;
  margin-top: -20px;
  width: 100%;
}

.no-x-strap-paddings {
  margin-right: -20px;
  margin-left: -20px;
  width: calc(100% + 40px);
}

/////////// Height ///////////
.max-height-600 {
  max-height: 600px;
}

.height-160 {
  height: 160px;
}

@media (min-width: 768px) {
  .max-md-height-80 {
    max-height: 80px;
  }
  .height-md-px-324 {
    height: 324px;
  }
  .max-md-height-45 {
    max-height: 45px;
  }
}

.max-height-auto {
  max-height: auto;
}

.max-height-45 {
  max-height: 45px;
}

.max-height-all {
  max-height: 100%;
}

.min-height-200 {
  min-height: 200px;
}

/////////// Width ///////////
.max-width-12 {
  max-width: 12rem;
}

.max-width-30 {
  max-width: 30rem;
}

.max-width-300 {
  max-width: 300px;
}

.max-width-700 {
  max-width: 700px;
}

.width-auto {
  width: auto !important;
}

@for $value from 1 through 100 {
  .width-#{$value} {
    width: percentage($value/100) !important;
  }

  .width-px-#{$value*10} {
    width: ($value) * 10 + px !important;
  }

  .height-px-#{$value*10} {
    height: ($value) * 10 + px !important;
  }

  .width-rem-#{$value} {
    width: ($value) + rem !important;
  }

  .min-width-rem-#{$value} {
    min-width: ($value) + rem !important;
  }

  .max-width-rem-#{$value} {
    max-width: ($value) + rem !important;
  }
}

@media (min-width: 576px) {
  @for $value from 1 through 100 {
    .width-sm-#{$value} {
      width: percentage($value/100) !important;
    }
  }
  @for $value from 1 through 100 {
    .width-md-#{$value} {
      width: percentage($value/100) !important;
    }
  }
  @for $value from 1 through 100 {
    .width-lg-#{$value} {
      width: percentage($value/100) !important;
    }
  }
  @for $value from 1 through 100 {
    .width-xl-#{$value} {
      width: percentage($value/100) !important;
    }
  }
}

@media (min-width: 768px) {
  @for $value from 1 through 100 {
    .width-md-#{$value} {
      width: percentage($value/100) !important;
    }
  }
  @for $value from 1 through 100 {
    .width-lg-#{$value} {
      width: percentage($value/100) !important;
    }
  }
  @for $value from 1 through 100 {
    .width-md-px-#{$value*10} {
      width: ($value) * 10 + px !important;
    }
  }
  @for $value from 1 through 100 {
    .width-xl-#{$value} {
      width: percentage($value/100) !important;
    }
  }
}

@media (min-width: 992px) {
  @for $value from 1 through 100 {
    .width-lg-#{$value} {
      width: percentage($value/100) !important;
    }
  }
  @for $value from 1 through 100 {
    .width-xl-#{$value} {
      width: percentage($value/100) !important;
    }
  }
}

@media (min-width: 1200px) {
  @for $value from 1 through 100 {
    .width-xl-#{$value} {
      width: percentage($value/100) !important;
    }
  }
}

/////////// Object fit ///////////
.fit-cover {
  object-fit: cover;
}

/////////// Border ///////////
@for $value from 1 through 12 {
  .border-width-#{$value} {
    border-width: $value + px !important;
  }
}

@media (min-width: 768px) {
  .border-md-right {
    border-right: 1px solid $border-color;
  }
  .border-md-bottom-0 {
    border-bottom: 0px !important;
  }
}

/////////// Cursor ///////////
.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.overflow-scroll {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

/////////// Flex ///////////

@for $value from 1 through 12 {
  .flex-gr-#{$value} {
    flex-grow: $value !important;
  }
}

@for $value from 1 through 12 {
  .flex-sh-#{$value} {
    flex-shrink: $value !important;
  }
}

.justify-self-start {
  -ms-flex-pack: start !important;
  justify-self: flex-start !important;
}

.justify-self-end {
  -ms-flex-pack: end !important;
  justify-self: flex-end !important;
}

.justify-self-center {
  -ms-flex-pack: center !important;
  justify-self: center !important;
}

/////////// Line-height ///////////

@for $first from 1 through 5 {
  .line-height-#{$first} {
    line-height: $first;
  }
}

/////////// Grid ///////////
.d-grid {
  display: grid;
}

.grid-area-a {
  grid-area: a;
}

.grid-area-b {
  grid-area: b;
}

.grid-area-c {
  grid-area: c;
}

@for $first from 1 through 24 {
  .grid-gap-#{$first} {
    grid-gap: $first + px;
  }
  @for $second from 1 through 24 {
    .grid-gap-#{$first}-#{$second} {
      grid-gap: $first + px $second + px;
    }
  }
}

@for $first from 1 through 4 {
  .grid-tmp-col-#{$first}-auto {
    grid-template-columns: $first + fr auto;
  }
  .grid-tmp-col-auto-#{$first} {
    grid-template-columns: auto $first + fr;
  }
  .grid-tmp-row-auto-#{$first} {
    grid-template-rows: auto $first + fr;
  }
  .grid-tmp-row-#{$first}-auto {
    grid-template-rows: $first + fr auto;
  }
  @for $second from 1 through 4 {
    .grid-tmp-col-#{$first}-#{$second} {
      grid-template-columns: $first + fr $second + fr;
    }
    .grid-tmp-row-#{$first}-#{$second} {
      grid-template-rows: $first + fr $second + fr;
    }
    .grid-tmp-rpt-col-#{$first}-#{$second} {
      grid-template-columns: repeat($first + fr, $second + fr);
    }
    .grid-tmp-rpt-row-#{$first}-#{$second} {
      grid-template-rows: repeat($first + fr, $second + fr);
    }
    .grid-tmp-row-#{$first*10}px-#{$second} {
      grid-template-rows: $first * 10 + px $second + fr;
    }
    .grid-tmp-row-#{$first*10}px-#{$second*10}px {
      grid-template-rows: $first * 10 + px $second * 10 + px;
    }
    .grid-tmp-row-#{$first}-#{$second*10}px {
      grid-template-rows: $first + fr $second * 10 + px;
    }
    @for $third from 1 through 4 {
      .grid-tmp-col-#{$first}-#{$second}-#{$third} {
        grid-template-columns: $first + fr $second + fr $third + fr;
      }
      .grid-tmp-row-#{$first}-#{$second}-#{$third} {
        grid-template-rows: $first + fr $second + fr $third + fr;
      }
      .grid-tmp-rpt-col-#{$first}-#{$second}-#{$third} {
        grid-template-columns: repeat($first + fr, $second + fr, $third + fr);
      }
      .grid-tmp-rpt-row-#{$first}-#{$second}-#{$third} {
        grid-template-rows: repeat($first + fr, $second + fr, $third + fr);
      }
      @for $fourth from 1 through 4 {
        .grid-tmp-col-#{$first}-#{$second}-#{$third}-#{$fourth} {
          grid-template-columns: $first + fr $second + fr $third + fr $fourth + fr;
        }
        .grid-tmp-row-#{$first}-#{$second}-#{$third}-#{$fourth} {
          grid-template-rows: $first + fr $second + fr $third + fr $fourth + fr;
        }
      }
    }
  }
}

.grid-tmp-col-auto-auto {
  grid-template-columns: auto auto;
}

.grid-tmp-row-auto-auto {
  grid-template-rows: auto auto;
}

/////////// Links ///////////
.more-link {
  font-weight: bold;
  color: $link-color-2 !important;
}

.mt {
  margin-top: $xs-padding;
}

.mb-last-0 {
  &:last-child {
    margin-bottom: 0 !important;
  }
}

.mt-sm {
  margin-top: $xs-padding;
}

.mb {
  margin-bottom: $xs-padding;
}

/////////// Tables ///////////

.valign-top {
  vertical-align: top !important;
}

.table-responsive {
  .table {
    width: auto;
    min-width: 100%;
  }
}

.table {
  font-size: 13px;

  &.td-md {
    td,
    th {
      min-width: 100px;
    }
  }

  &.td-lg {
    td,
    th {
      min-width: 150px;
    }
  }

  td,
  th {
    vertical-align: middle;

    &.md {
      width: 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;
      overflow: hidden;
    }

    &.lg {
      width: 150px !important;
      min-width: 150px !important;
      max-width: 150px !important;
      overflow: hidden;
    }

    &.xl {
      width: 200px !important;
      min-width: 200px !important;
      max-width: 200px !important;
      overflow: hidden;
    }

    &.xxl {
      width: 300px !important;
      min-width: 300px !important;
      max-width: 300px !important;
      overflow: hidden;
    }

    &.pr-sm {
      padding-right: 3px;
    }

    &.pl-sm {
      padding-left: 3px;
    }
  }

  tr:nth-child(2n) {
    background-color: $light;
  }

  thead {
    background-color: #f5f5f5;
    color: #777e90;
    font-size: 14px;

    tr {
      border-bottom: 1px solid $gray-200;
    }
  }

  .btn-sm {
    font-size: 13px;
  }

  @include media-breakpoint-up(md) {
    &:not(.table-sm) {
      td,
      th {
        padding: 0.4rem 1.5rem;

        &.pr-sm {
          padding-right: 3px;
        }

        &.pl-sm {
          padding-left: 3px;
        }
      }
    }
  }

  &.table-sm {
    th {
      padding: 0.4rem 1rem;
    }
  }

  &.table-vertical {
    tr {
      td {
        display: block;
        padding: 0.5rem 0 !important;

        &.bold {
          padding: 0.5rem 0 0.5rem 1rem !important;
        }
      }
    }
  }

  &.table-hover tbody tr:hover {
    cursor: pointer;
  }
}

.table-bordered thead td,
.table-bordered thead th {
  border-bottom-width: 1px;
}

.table-bordered,
.table-bordered td,
.table-bordered th {
  border: 0px solid transparent;
}

.device-table-fixed-rows {
  tr:nth-child(even) > td {
    background: #f5f5f5 !important;
  }

  & > thead > tr > th,
  .react-bootstrap-table table > thead > th {
    background: #f5f5f5 !important;
  }

  & > thead > tr > th:nth-child(1),
  .react-bootstrap-table table > thead > th:nth-child(1),
  & > tbody > tr > td:nth-child(1),
  .react-bootstrap-table table > thead > tr:nth-child(1) > th:nth-child(1) {
    @include fixed-column;
    left: 0;
  }

  & > thead > tr > th:nth-child(2),
  .react-bootstrap-table table > thead > th:nth-child(2),
  & > tbody > tr > td:nth-child(2),
  .react-bootstrap-table table > thead > tr:nth-child(2) > th:nth-child(2) {
    @include fixed-column;
    left: 3.28rem;
  }

  & > thead > tr > th:nth-child(3),
  .react-bootstrap-table table > thead > th:nth-child(3),
  & > tbody > tr > td:nth-child(3),
  .react-bootstrap-table table > thead > tr:nth-child(3) > th:nth-child(3) {
    @include fixed-column;
    left: 10.85rem;
  }

  & > thead > tr > th:nth-child(4),
  .react-bootstrap-table table > thead > th:nth-child(4),
  & > tbody > tr > td:nth-child(4),
  .react-bootstrap-table table > thead > tr:nth-child(4) > th:nth-child(4) {
    @include fixed-column;
    left: 15.4rem;
  }

  & > tbody > tr > td:nth-child(4),
  .react-bootstrap-table table > thead > tr:nth-child(4) > th:nth-child(4) {
    box-shadow: inset -1px 0 0px 0px #e5e5e5;
  }

  & > thead > tr > th:nth-last-child(1),
  .react-bootstrap-table table > thead > th:nth-last-child(1),
  & > tbody > tr > td:nth-last-child(1),
  .react-bootstrap-table table > thead > tr:nth-last-child(1) > th:nth-last-child(1) {
    @include fixed-column;
    right: 0rem;
  }

  &.table-hover tbody tr:hover {
    & > td:nth-child(1),
    .react-bootstrap-table table > thead > tr:nth-child(1) > th:nth-child(1) {
      background: #f2f6fe;
    }

    & > td:nth-child(2),
    .react-bootstrap-table table > thead > tr:nth-child(2) > th:nth-child(2) {
      background: #f2f6fe;
    }

    & > td:nth-child(3),
    .react-bootstrap-table table > thead > tr:nth-child(3) > th:nth-child(3) {
      background: #f2f6fe;
    }

    & > td:nth-child(4),
    .react-bootstrap-table table > thead > tr:nth-child(4) > th:nth-child(4) {
      background: #f2f6fe;
    }

    & > td:nth-last-child(1),
    .react-bootstrap-table table > thead > tr:nth-last-child(1) > th:nth-last-child(1) {
      background: #f2f6fe;
    }
  }
}

.react-bootstrap-table {
  @extend .table-responsive;
  background-color: $white;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  text-align: center;
}

.only-inner-border {
  &:first-child {
    border-top: none;
  }

  border-bottom: none;
  border-left: none;
  border-right: none;
}

.only-inner-border-and-last-bottom {
  @extend .only-inner-border;

  &:last-child {
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  }
}

.table-stick-head {
  thead tr {
    background: #f5f5f5;
    position: sticky;
    top: -1px;
    z-index: 90;
  }

  max-height: 25rem;
  overflow: auto;
}

///////////Calendar///////////
.react-calendar {
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.2);
  border: none !important;
  border-radius: 5px;
}

///////////Dropdown///////////
.dropdown {
  .dropdown-links {
    flex-direction: column;

    a {
      border-color: transparent;
      width: 100%;
      text-align: left;
    }
  }
}

.dropdown-toggle {
  &.custom-dropdown {
    @extend .bordered-dropdown;

    &:after {
      position: absolute;
      right: 3px;
      top: 3px;
    }

    &.filter {
      &:after {
        background: $white url('../../images/filter-solid.svg') center center no-repeat;
        background-size: 50%;
      }
    }

    &.sort {
      &:after {
        background: $white url('../../images/sort-amount-down-solid.svg') center center no-repeat;
        background-size: 50%;
      }
    }

    &.select {
      &:after {
        background: $white url('../../images/caret-down-solid.svg') center center no-repeat;
        background-size: 30%;
      }
    }
  }

  &.dropdown-icon {
    &::after {
      content: none;
    }
  }

  &:not(.dropdown-icon) {
    &::after {
      display: inline-block;
      width: 7px;
      height: 5px;
      padding: 15px;
      background: $light url('../../images/chevron-down-solid.svg') center center no-repeat;
      background-size: 30%;
      content: '';
      border-top: 0;
      border-right: 0;
      border-bottom: 0;
      border-left: 0;
      border-radius: $border-radius;
      margin-left: 15px;
      vertical-align: middle;
    }
  }

  &.bordered-dropdown {
    border: $border-light;
    text-align: left;
  }
}

.dropdown-item {
  & > a {
    @extend .dropdown-item;
    padding: 0;
  }
}

///////////Buttons///////////

.btn {
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 20px;

  &:disabled,
  .disabled {
    cursor: default;
  }
}

.btn-dark {
  background-color: $medium-dark;

  i.la {
    color: $gray-200;
    font-size: 1.2rem;
    vertical-align: middle;
  }
}

.btn-darkBlue {
  background-color: #4665e9;
  color: #fff;
}

.btn-darkBlue:hover {
  background-color: #2447e5 !important;
  color: #fff;
}

.btn-darkBlue:focus {
  background-color: #4665e9 !important;
}

.btn-primary {
  border-color: transparent;
  background-color: $success;

  &.disabled,
  &:disabled {
    border-color: transparent;
    background-color: lighten($success, 15%);
  }

  &:not(:disabled):not(.disabled) {
    &:hover,
    &:active,
    &:focus {
      background-color: darken($success, 5%);
      border-color: transparent;
    }
  }
}

.btn-yellow-light {
  background: rgba(255, 193, 7, 0.2);
  border: 1px solid rgba(255, 193, 7, 0.5);

  &:hover,
  &:active,
  &:focus {
    background-color: darken(rgba(255, 193, 7, 0.2), 5%) !important;
    border-color: transparent;
  }
}

.btn-secondary {
  border-color: transparent;

  &.disabled,
  &:disabled {
    border-color: transparent;
  }

  &:not(:disabled):not(.disabled) {
    &:hover,
    &:active,
    &:focus {
      border-color: transparent;
      background-color: darken($blue-light, 5%);
    }
  }
}

.btn-success {
  border-color: darken($success, 15%);

  &.disabled,
  &:disabled {
    border-color: darken($success, 15%);
  }

  &:not(:disabled):not(.disabled) {
    &:hover,
    &:active,
    &:focus {
      border-color: darken($success, 20%);
      background-color: darken($success, 5%);
    }
  }
}

.btn-danger {
  border-color: transparent;

  &.disabled,
  &:disabled {
    border-color: transparent;
  }

  &:not(:disabled):not(.disabled) {
    &:hover,
    &:active,
    &:focus {
      border-color: transparent;
      background-color: darken($danger, 5%);
    }
  }
}

.btn-warning {
  border-color: darken($warning, 15%);

  &.disabled,
  &:disabled {
    border-color: darken($warning, 15%);
  }

  &:not(:disabled):not(.disabled) {
    &:hover,
    &:active,
    &:focus {
      border-color: darken($warning, 20%);
      background-color: darken($warning, 5%);
    }
  }
}

.btn-light {
  border: 1px solid #f5f5f5;
  // border-color: darken($light, 15%);
  background-color: #f5f5f5;

  &.disabled,
  &:disabled {
    border-color: darken($light, 15%);
  }

  &:not(:disabled):not(.disabled) {
    &:hover,
    &:active,
    &:focus {
      // border-color: darken($light, 20%);
      background-color: darken($light, 5%);
    }
  }
}

.btn-gray {
  border: none;
  background-color: #f5f5f5;

  &.disabled,
  &:disabled {
    border-color: darken($light, 15%);
  }

  &:not(:disabled):not(.disabled) {
    &:hover,
    &:active,
    &:focus {
      border-color: darken($light, 20%);
      background-color: darken($light, 5%);
    }
  }

  &__isDisabled {
    border-color: darken($light, 20%);
    background-color: darken($light, 5%);

    &:hover,
    &:active,
    &:focus {
      border-color: darken($light, 20%);
      background-color: darken($light, 5%);
    }
  }
}

.btn-white {
  border-color: darken($white, 15%);
  background-color: $white !important;

  &.disabled,
  &:disabled {
    border-color: darken($white, 15%);
  }

  &:not(:disabled):not(.disabled) {
    &:hover,
    &:active,
    &:focus {
      border-color: darken($light, 20%);
      background-color: $white;
    }
  }
}

.btn-pink {
  border-color: $danger;
  background-color: transparent;
  color: $danger;

  &.disabled,
  &:disabled {
    border-color: $danger;
  }

  &:not(:disabled):not(.disabled) {
    &:hover,
    &:active,
    &:focus,
    &.active {
      border-color: darken($danger, 5%);
      background-color: transparent;
      color: darken($danger, 5%);
    }
  }
}

.btn-blue {
  border: 1px solid rgba(70, 100, 233, 0.5);
  background-color: rgba(84, 138, 243, 0.2);

  &.disabled,
  &:disabled {
    border-color: $primary;
  }

  &:not(:disabled):not(.disabled) {
    &:hover,
    &:active,
    &:focus,
    &.active {
      border: 1px solid rgba(70, 100, 233, 0.5);
      background-color: rgba(84, 138, 243, 0.18);
    }
  }
}

.btn-blue-filled {
  border: 1px solid #548af3;
  background-color: #548af3;
  color: #fff;

  &.disabled,
  &:disabled {
    border-color: $primary;
  }

  &:not(:disabled):not(.disabled) {
    &:hover,
    &:active,
    &:focus,
    &.active {
      border: 1px solid #548af3;
      background-color: #548af3;
      color: #fff;
    }
  }
}

.btn-orange {
  border-color: $warning;
  background-color: lighten($warning, 25%);
  color: $medium-dark;

  &.disabled,
  &:disabled {
    border-color: $warning;
  }

  &:not(:disabled):not(.disabled) {
    &:hover,
    &:active,
    &:focus,
    &.active {
      border-color: darken($warning, 5%);
      background-color: lighten($warning, 10%);
      color: darken($dark, 5%);
    }
  }
}

.btn-outline-primary {
  color: $gray-900;
  background-color: $white;
  border-color: $gray-300;

  &:hover {
    border-color: darken($gray-300, 20%);
    background-color: $white;
    color: $gray-900;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    border-color: transparent;
  }
}

.btn-default {
  border-color: $white;
  background-color: $white;

  &.disabled,
  &:disabled,
  &:hover,
  &:focus,
  &:active {
    border-color: $white;
    box-shadow: none;
    background-color: $white;
  }
}

.btn-link {
  font-size: small;

  &.no-padding {
    padding: 0 !important;
  }
}

.btn-sm {
  min-width: 30px;
}

.btn-lg {
  height: 50px;
}

.btn-sm,
.btn-group-sm > .btn {
  font-size: 1rem;
}

.btn-xs {
  padding: 5px;
  min-width: 30px;
  min-height: 30px;
}

.btn-expand {
  position: absolute;
  left: -22px;
  top: calc(50% - 21px);
  transition: transform 0.2s ease-in-out;

  &:focus,
  &:active {
    box-shadow: none;
  }
}

.expand-item {
  transition: 0.2s all ease-in-out;
  left: 0;
  width: 100%;

  .btn-expand {
    transform: rotate(-90deg);
  }

  &[data-open='true'] {
    box-shadow: 0px 8px 32px 0px rgba(51, 51, 51, 0.06);
    left: -10px;
    width: calc(100% + 10px);
    transition: 0.2s all ease-in-out;

    .btn-expand {
      transform: rotate(0);
    }
  }

  & ~ .shown {
    padding: 1rem 1.25rem 0.75rem;
    border-left: 2px solid $primary;
    border-right: 1px solid $gray-300;
    background-color: $gray-200;
  }
}

.choose-billing-type {
  &:first-child {
    margin-right: 0.5rem;
  }
}

.bp-btn {
  @extend .btn;
  @extend .btn-light;
  min-width: 100%;
  width: auto;
  height: 120px;
  padding: 1.25rem 1.15rem;
  color: #999;
}

/////////// Cards ///////////

.card {
  font-size: 1rem;

  &.card-colored {
    border: 1px solid transparent;

    .card-body {
      border: 1px solid $gray-300;
      border-top-color: transparent;
      border-radius: 0 0 calc(0.3rem - 1px) calc(0.3rem - 1px);
      font-size: 12px;
      color: $gray-500;

      table {
        tr {
          &:last-child {
            color: #333;
          }

          td {
            &:last-child {
              text-align: right;
            }
          }
        }
      }
    }
  }

  .card-header {
    font-weight: bold;
    text-align: center;

    &.warning {
      background: rgba(237, 177, 0, 0.1);
      border: 1px solid rgba(237, 177, 0, 0.3);
    }

    &.success {
      background: rgba(78, 183, 154, 0.1);
      border: 1px solid rgba(78, 183, 154, 0.3);
    }

    &.secondary {
      background: rgba(60, 122, 241, 0.1);
      border: 1px solid rgba(60, 122, 241, 0.3);
    }

    &.light {
      background: $gray-200;
      border: 1px solid rgba(1, 1, 1, 0.1);

      &.borderless {
        border: none;
      }
    }
  }

  .react-bootstrap-table {
    border: none;
  }
}

.card-columns {
  column-count: 1;

  @include media-breakpoint-up(md) {
    column-count: 2;
  }
}

.card {
  &.card-sm {
    .card-body {
      padding: 8px;
    }
  }

  &.card-md {
    .card-body {
      padding: 10px;
    }
  }

  .avatar-img {
    width: 60px;
    height: 60px;
    overflow: hidden;
    border-radius: $btn-border-radius;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -20%;

    &.lg {
      width: 120px;
      height: 120px;

      label {
        width: 120px;
        height: 120px;

        img {
          width: auto;
          height: 100%;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    img {
      width: auto;
      height: 100%;
    }

    &.round-img {
      img {
        border-radius: 50%;
      }
    }
  }

  &.no-top-border {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &.no-bottom-radius {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

/////////// Form fields ///////////

.form-group {
  label:not(.onoffswitch-label) {
    font-weight: bold;
    -webkit-font-smoothing: auto;
    padding: 10px 0 10px 15px;
    margin-bottom: 5px;
  }

  @include media-breakpoint-up(md) {
    margin-bottom: 1rem;
  }
}

.form-check-label {
  .info-text {
    font-size: 12px;
    color: $gray-500;
  }
}

.form-control {
  padding: 0.75rem 20px 0.75rem 15px;

  &__search {
    background-color: #f5f5f5;
    border: none;

    &:focus {
      color: #333;
      background-color: #f2f2f2;
      border: none;
      outline: 0;
      box-shadow: none;
    }
  }
}

// .form-control-sm {
//   font-size: 1rem;
//   padding: 0.35rem 20px 0.35rem 10px;
// }

.form-check {
  padding-left: 0;
  margin-bottom: 1rem;

  .form-check-input {
    visibility: hidden;
    opacity: 0;
    position: absolute;
  }

  .form-check-label {
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    vertical-align: text-bottom;

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      display: inline-block;
      transition: all 0.2s ease-in-out;
    }
  }

  .form-check-label-btn {
    @extend .btn;
    @extend .btn-white;
    padding: 0;
    height: 100%;
    width: 100%;
    display: flex;
    font-size: small;
    align-content: center;
    flex-direction: column;
    justify-content: center;
    color: $gray-500;
    cursor: pointer;

    &:hover {
      background-color: rgba(84, 138, 243, 0.2);
      border: 1px solid $primary;
      color: $primary;
    }

    &:active {
      background-color: rgba(84, 138, 243, 0.4) !important;
      border: 1px solid $primary !important;
      color: $primary;
    }

    &.btn-round {
      line-height: 15px;
      border-radius: 50%;
      height: 30px;
      width: 30px;
      padding: 5px;
    }

    i {
      width: 100%;
      font-size: 16px;
      margin-bottom: 3px;
    }

    span {
      font-weight: bold;
      font-size: 12px;
    }

    select {
      border: 0;
      border-radius: 0;
      color: $primary;
      @extend .border-top;
    }

    &.btn-for-days {
      border: 1px solid $gray-400;
      line-height: 15px;
      border-radius: 50%;
      height: 30px;
      width: 30px;
      padding: 5px;

      &.off {
        background-color: $white;
        color: $gray-500;
      }

      &.on {
        background-color: $success !important;
        color: $white;
        border: 0;
      }

      &:disabled {
        border: 1px solid $gray-300;
        background-color: lighten($gray-200, 5%);
        color: lighten($gray-900, 20%);
      }
    }
  }

  .form-check-label-btn-selected {
    @extend .form-check-label-btn;
    background-color: rgba(84, 138, 243, 0.2);
    border: 1px solid $primary;
    color: $primary;
    font-size: small;

    &:hover {
      background-color: rgba(84, 138, 243, 0.1) !important;
      border: 1px solid $primary !important;
    }
  }

  .form-check-label-btn-selected {
    .invalid-feedback {
      position: absolute;
      bottom: -30px;
    }
  }

  .form-check-input {
    &[type='checkbox'] {
      ~ .form-check-label {
        &:before {
          border-radius: $border-radius-sm;
          border: 1px solid $gray-300;
          width: 20px;
          height: 20px;
        }

        &:after {
          opacity: 0;
          width: 20px;
          height: 20px;
        }
      }

      &:checked {
        ~ .form-check-label {
          &:after {
            opacity: 1;
            background: url('../../images/check-solid-blue.svg') center center no-repeat;
          }
        }

        ~ .form-check-label-btn {
          &.primary {
            @extend .btn-primary;
          }

          &.disabled {
            background-color: $gray-400;
            border-color: 1px solid $gray-300;
            cursor: default;
          }
        }
      }

      &:disabled {
        ~ .form-check-label {
          color: lighten($gray-500, 15%);
          cursor: default;

          &:before {
            border-color: lighten($gray-300, 5%);
          }
        }
      }
    }

    &[type='checkbox'][disabled] {
      ~ .form-check-label {
        &:after {
          opacity: 1;
          background: none;
          border: 1px solid $gray-300;
        }
      }

      &:checked {
        ~ .form-check-label {
          &:after {
            background: url('../../images/check-disabled.svg') center left no-repeat;
          }
        }
      }
    }

    &[type='radio'] {
      ~ .form-check-label {
        &:before {
          width: 16px;
          height: 16px;
          top: 3px;
          background: url('../../images/circle-regular-inactive.svg') center center no-repeat;
        }

        &:after {
          width: 10px;
          height: 10px;
          top: 3px;
          z-index: 1;
          background: url('../../images/circle-solid.svg') center center no-repeat;
          opacity: 0;
          margin: 3px;
        }
      }

      &:checked {
        ~ .form-check-label {
          &:before {
            background: url('../../images/circle-regular.svg') center center no-repeat;
          }

          &:after {
            opacity: 1;
          }
        }

        ~ .form-check-label-btn {
          @extend .btn-light:active;
          color: $gray-600;

          &.primary {
            @extend .btn-primary;
          }
        }
      }

      &:disabled {
        ~ .form-check-label {
          color: lighten($gray-500, 15%);
          cursor: default;

          &:before {
            background: url('../../images/circle-regular-dis.svg') center center no-repeat;
          }
        }
      }
    }
  }
}

.checkbox-select-all {
  &[type='checkbox'] {
    &:checked {
      ~ .form-check-label {
        &:after {
          background: url('../../images/checkbox-select-all.svg') center center no-repeat !important;
        }
      }
    }
  }
}

.form-check-btn-wrp {
  display: flex;
  flex-wrap: wrap;

  .form-check-inline {
    align-items: baseline;
    width: 46%;
    height: 100px;
    flex-grow: 1;

    &:nth-child(2n) {
      margin-right: 0;
    }
  }

  .danger {
    border: 1px solid $error-color;
    border-radius: 3px;
  }
}

.form-check-label-btn-db {
  @extend .form-check-label-btn;
  height: 8.5rem !important;

  &:hover {
    color: $blue-dark !important;
  }
}

.form-check-label-btn-selected-db {
  @extend .form-check-label-btn-selected;
  color: $blue-dark !important;
  height: 8.5rem !important;
}

@include media-breakpoint-up(md) {
  .form-check-btn-wrp {
    flex-wrap: nowrap;
    align-items: stretch;

    .form-check-inline {
      width: 100%;
      height: 100px;

      &:nth-child(2n) {
        margin-right: 0.75rem;
      }
    }
  }
}

.input-group > .input-group-prepend > .btn,
.form-check .input-group > .input-group-prepend > .form-check-label-btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.form-check .input-group > .input-group-append:not(:last-child) > .form-check-label-btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.form-check
  .input-group
  > .input-group-append:last-child
  > .form-check-label-btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
}

.form-control:valid ~ .invalid-feedback {
  display: none;
}

.was-validated .form-control:valid,
.react-select-isvalid__control,
.form-control.is-valid {
  border-color: $success !important;
}

.was-validated .form-control:invalid,
.react-select-invalid__control,
.form-control.is-invalid {
  border-color: $danger !important;
}

.onoffswitch {
  position: relative;
  width: 35px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  .onoffswitch-checkbox {
    display: none;
  }

  .onoffswitch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid $gray-300;
    border-radius: 12rem;
  }

  .onoffswitch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
  }

  .onoffswitch-inner:before,
  .onoffswitch-inner:after {
    display: block;
    float: left;
    width: 50%;
    height: 20px;
    padding: 0;
    box-sizing: border-box;
    border: 1px solid transparent;
    background-clip: padding-box;
  }

  .onoffswitch-inner:before {
    content: '';
    padding-left: 10px;
    background-color: #fff;
    color: #fff;
  }

  .onoffswitch-inner:after {
    content: '';
    padding-right: 10px;
    background-color: #fff;
    color: #666;
    text-align: right;
  }

  .onoffswitch-switch {
    display: block;
    width: 16px;
    height: 17px;
    margin: 0px;
    background: #e5e5e5;
    position: absolute;
    top: 3px;
    bottom: 3px;
    right: 16px;
    transition: all 0.1s ease-in 0s;
    border-radius: 10rem;
  }

  .onoffswitch-checkbox:checked + .onoffswitch-label {
    border-color: $primary;

    .onoffswitch-inner {
      margin-left: 0;
    }

    .onoffswitch-switch {
      right: 3px;
      background-color: $primary;
    }
  }
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus,
.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  box-shadow: 0 0 1px 0 $danger;
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus,
.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
  box-shadow: 0 0 1px 0 $success;
}

.ranged-inputs-wrp {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.ranged-input-group {
  width: 136px;
  text-align: center;

  label {
    padding: 10px 0;
    font-weight: bold;
  }

  .ranged-input {
    display: flex;
    justify-content: center;
    height: 3rem;
    width: 136px;
    align-items: center;
    margin: 0 auto;
  }

  .btn-round {
    font-size: 15px;
    line-height: 15px;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    padding: 5px;
    border: none;
    background-color: $gray-200;

    &:disabled {
      background-color: lighten($gray-200, 5%);
      color: lighten($gray-900, 20%);
    }

    &:hover,
    &:active,
    &:focus {
      &:not(:disabled) {
        background-color: darken($gray-200, 5%);
        cursor: pointer;
      }
    }
  }

  .btn-squared {
    @extend .btn-round;
    border-radius: 0;
    border: 1px solid #eee;
    height: 100%;

    :disabled {
      background-color: lighten($gray-200, 5%);
      color: lighten($gray-900, 20%);
    }
  }

  input {
    height: 100%;
    width: 46px;
    font-size: 18px;
    text-align: center;
    border: 1px solid #eee;
    border-radius: 0;
    -webkit-appearance: none;
  }
}

.input-group:not(.input-group-sm) {
  .input-group-append {
    height: 40px;
  }
}

.input-group {
  line-height: 39px;
}

.react-daterange-picker {
  flex-grow: 4;
}

.react-daterange-picker__wrapper,
.react-date-picker__wrapper {
  border: thin solid rgba(0, 0, 0, 0.125) !important;
  background-color: $white;
  border-radius: 0.2rem 0 0 0.2rem;
  width: 100%;
  height: 40px;
}

.react-date-picker__wrapper {
  border-radius: 0.2rem;
  padding: 0 10px;
  border: 1px solid #eeeeee !important;
}

.react-calendar__tile--active {
  background: $primary !important;
}

.react-daterange-picker__inputGroup:first-child {
  flex-grow: 0 !important;
}

.react-date-picker {
  height: 40px;
}

.custom-DatePicker-styling,
.custom-DatePicker-styling > div.react-datepicker-wrapper,
.custom-DatePicker-styling
  > div
  > div.react-datepicker__input-container
  .custom-DatePicker-styling
  > div
  > div.react-datepicker__input-container
  input {
  width: 100%;
}

// @include media-breakpoint-up(sm) {
//   .avatar-block {
//     margin-top: -60px;
//   }
// }

.ava-background-wrapper {
  position: relative;
  height: 120px;
  width: 100%;
  overflow: hidden;

  img {
    position: absolute;
    top: -50%;
    filter: blur(10px);
  }
}

/////////// Decoration ///////////

.async {
  & > * {
    animation: is-loaded 0.25s ease-in 1;
  }
}

@keyframes is-loaded {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.green-striped {
  @include stripes($success);
  color: $white;
  font-weight: bold;
}

.bg-success-light {
  background: #e4f4f0;
}

.bg-warning-light {
  background: #fff6da;
}

.bg-danger-light {
  background: #fde7e7;
}

.bg-gray,
.bg-grey {
  background-color: $light;
}

.bg-clear {
  background-color: rgba(84, 138, 243, 0);
}

/////////// Spinner //////////

.btn.btn-primary {
  .lds-facebook div {
    background: white;
  }
}

.lds-facebook {
  display: inline-block;
  position: relative;
  transform: scale(0.4) translateY(-15px);
  height: 14px;
  width: 34px;
}

.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 6px;
  width: 13px;
  background: $primary;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.lds-facebook div:nth-child(1) {
  left: 6px;
  animation-delay: -0.24s;
}

.lds-facebook div:nth-child(2) {
  left: 26px;
  animation-delay: -0.12s;
}

.lds-facebook div:nth-child(3) {
  left: 45px;
  animation-delay: 0s;
}

@keyframes lds-facebook {
  0% {
    top: 6px;
    height: 51px;
  }
  50%,
  100% {
    top: 19px;
    height: 26px;
  }
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;

  &.sm {
    width: 20px;
    height: 20px;
    transform: scale(0.5) translateX(-30px);
  }

  &.inside-btn {
    width: 20px;
    height: 14px;
    margin-left: 15px;
    transform: scale(0.5) translateX(-30px);
  }
}

.lds-ellipsis {
  div {
    position: absolute;
    top: 27px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: $primary;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }

  &.sm div {
    top: 10px;
  }

  &.inside-btn div {
    top: 10px;
  }
}

.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}

//////////////

.btn-block {
  display: flex;
  justify-content: space-between;

  & > .btn {
    flex-grow: 1;
    margin-right: 0.5rem;

    &:last-child {
      margin-right: 0;
    }
  }
}

/////////// Pagination ///////////

.react-bootstrap-table-pagination {
  @extend .card;
  margin-left: 0;
  margin-right: 0;

  & > div {
    padding: 8px;

    &:first-child {
      order: 2;
    }

    &:last-child {
      padding-bottom: 0;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .dropdown.react-bs-table-sizePerPage-dropdown {
    .btn {
      padding: 4.5px 0 4.5px 10px;
      border-color: #e5e5e5;
      margin-right: 10px;

      &.btn-secondary {
        &:hover,
        &:focus,
        &:active {
          border-color: #e5e5e5;
          background-color: $white;
        }
      }

      &:after {
        background-color: $white;
      }
    }
  }

  ul.pagination {
    margin-bottom: 0;
  }
}

.react-bootstrap-table-pagination-list-hidden {
  display: none;
}

@include media-breakpoint-up(md) {
  .react-bootstrap-table-pagination {
    flex-direction: row;

    & > div {
      &:first-child {
        order: 0;
      }

      &:last-child {
        padding-bottom: 8px;
      }
    }
  }

  .pagination {
    justify-content: flex-end;
  }
}

/////////// Pills ///////////

.arrow-pills {
  .nav-link {
    position: relative;
    background: $white;
    border-top: 1px solid $gray-300;
    border-bottom: 1px solid $gray-300;
    padding: 6px;
    border-radius: 0;
    color: $gray-400;

    &:before {
      content: '';
      display: block;
      width: 18px;
      height: 33px;
      position: absolute;
      left: -16px;
      top: -1px;
      background: url(../../images/2_inactive_tail.jpg) center center no-repeat;
      background-size: contain;
    }

    &:after {
      content: '';
      display: block;
      width: 18px;
      height: 33px;
      position: absolute;
      right: -1px;
      top: -1px;
      background: url(../../images/2_inactive_arrow.jpg) center center no-repeat;
      background-size: contain;
    }

    &:first-child {
      border-left: 1px solid $gray-300;
      border-radius: 3px;

      &:before {
        background: none;
        width: 0;
        height: 0;
      }
    }

    &:last-child {
      border-right: 1px solid $gray-300;
      border-radius: 3px;

      &:after {
        background: none;
        width: 0;
        height: 0;
      }
    }

    &.active {
      background: $secondary;
      border-top: 1px solid #889ff2;
      border-bottom: 1px solid #889ff2;
      color: $gray-900;
      font-weight: bold;

      &:before {
        background: url('../../images/2_active_tail.jpg') center center no-repeat;
        background-size: contain;
        height: 33.5px;
        z-index: 2;
      }

      &:after {
        background: url('../../images/2_active_arrow.jpg') center center no-repeat;
        background-size: contain;
        height: 33.5px;
        z-index: 2;
      }

      &:first-child {
        border-left: 1px solid #889ff2;

        &:before {
          background: url('../../images/2_active_arrow.jpg') center center no-repeat;
          background-size: contain;
        }
      }

      &:last-child {
        border-right: 1px solid #889ff2;

        &:after {
          background: url('../../images/2_active_tail.jpg') center center no-repeat;
          background-size: contain;
        }
      }
    }
  }
}

/////////// Common blocks ///////////

.cockpit {
  .btn-group {
    width: 100%;
  }

  .btn {
    height: 35px;
    padding: 0.55rem 1.15rem;
    flex-grow: 1;

    &.btn-primary {
      min-width: 45px;
      text-align: center;
    }
  }

  &.borderless-bottom {
    border-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  @include media-breakpoint-up(lg) {
    &.lg {
      .btn {
        padding: 1.4rem 1.15rem;
        height: 60px;
        font-size: 14px;

        &.active {
          font-weight: bold;
        }
      }
    }
  }
}

.server-list-header {
  display: flex;
  padding: 15px;
  border: 1px solid $gray-300;
  background-color: $white;
  border-radius: $border-radius;
  flex-direction: column;

  & > .btn {
    margin-top: 8px;
    order: 3;
  }

  .input-group {
    max-width: 100%;
    order: 1;
  }

  .search-group {
    max-width: 100%;
  }

  .select-container {
    order: 2;
  }
}

.cockpit-part {
  margin-top: 10px;
}

.my-account-ssh-table-cell {
  padding: 5px;
  word-break: break-all;
  font-weight: normal;
}

.info-block {
  display: flex;

  &:not(:last-child) {
    .ib-item {
      border-bottom: 1px dashed $gray-300;
    }
  }

  &.full-sm {
    flex-wrap: wrap;

    .ib-item {
      width: 100%;
      flex: auto 0;

      &:not(:last-child) {
        border-right: none;
        border-bottom: 1px dashed $gray-300;
      }

      i.fas,
      i.far,
      i.fab {
        display: inline-block;
      }
    }
  }

  @include media-breakpoint-up(md) {
    &.full-sm {
      flex-wrap: nowrap;

      .ib-item {
        &:not(:last-child) {
          border-right: 1px dashed $gray-300;
          border-bottom: none;
        }
      }
    }
  }

  .ib-item {
    display: flex;
    flex-grow: 1;
    flex: 2 0;
    justify-content: space-between;
    align-items: center;
    padding: 1.2rem 2rem;
    font-size: 14px;

    &:not(:last-child) {
      border-right: 1px dashed $gray-300;
    }

    p {
      font-size: 14px;
      margin-bottom: 0;

      .lead {
        font-size: 24px;
        display: block;
        font-weight: normal;
      }
    }

    i.fas,
    i.far,
    i.fab {
      display: none;
    }

    @include media-breakpoint-up(md) {
      i.fas,
      i.far,
      i.fab {
        display: inline-block;
      }
    }
  }
}

.info-block-borderless {
  display: flex;
  flex-wrap: wrap;

  &:not(:last-child) {
    .ib-item {
      border-bottom: 1px dashed $gray-300;
    }
  }

  &:last-child {
    .ib-item {
      &:not(:last-child) {
        border-bottom: 1px dashed $gray-300;
      }
    }
  }

  .ib-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.2rem 0;
    width: 100%;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }

    .bold {
      margin-bottom: 5px;
    }

    i {
      order: 2;
    }
  }
}

////////// Icons////////

.fas,
.far,
.fab {
  &.bg {
    position: relative;
    width: 35px;
    height: 35px;
    font-size: 15px;
    border-radius: 50%;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      padding: 10px;
      font-size: 15px;
    }

    &.bg-green {
      background-color: $success;
      color: $white;
    }

    &.bg-blue {
      background-color: $primary;
      color: $white;
    }

    &.bg-red {
      background-color: $danger;
      color: $white;
    }
  }
}

.draas-icon {
  width: 1rem;
  height: 1rem;
  background: url('../../images/draas-menu-icon.svg') center center no-repeat;
  background-size: contain;
}

.firewall-icon {
  width: 1rem;
  height: 1rem;
  background: url('../../images/firewall-icon.svg') center center no-repeat;
  background-size: contain;
}

.load-balancer-icon {
  width: 1rem;
  height: 1rem;
  background: url('../../images/lb-icon.svg') center center no-repeat;
  background-size: contain;
}

.backups-icon {
  width: 1rem;
  height: 1rem;
  background: url('../../images/backups-icon.svg') center center no-repeat;
  background-size: contain;
}

.mysql-icon {
  width: 2rem;
  height: 2rem;
  background: url('../../images/mysql-icon.svg') center center no-repeat;
  background-size: contain;
}

.postgresql-icon {
  width: 2rem;
  height: 2rem;
  background: url('../../images/postgresql-icon.svg') center center no-repeat;
  background-size: contain;
}

.mongodb-icon {
  width: 2rem;
  height: 2rem;
  background: url('../../images/mongodb-icon.svg') center center no-repeat;
  background-size: contain;
}

/////////// Alert ///////////

.alert.global-alert {
  position: fixed;
  top: 10px;
  left: 80px;
  z-index: 1080;
  width: calc(100% - 100px);
}

.sidebar-open {
  .alert.global-alert {
    left: 305px;
    width: calc(100% - 325px);
  }
}

/////////// Popover ///////////

.popover {
  .popover-inner {
    padding: $md-padding;
  }

  .popover-header {
    text-align: center;
    padding-bottom: 0.5rem;
  }
}

.popover-fw {
  margin-bottom: 10rem;
}

/////////// Badge ///////////

.badge-warning {
  background-color: rgba(237, 177, 0, 0.1);

  &.inherited {
    background-color: #ffc107;
  }
}

.badge {
  &.sm {
    padding: 2px 10px;
    font-size: 10px;
    font-weight: bold;
    line-height: 14px;
  }
  &.md {
    padding: 4px 8px;
    font-size: 12px;
    font-weight: bold;
    line-height: 14px;
  }
}

///////// Phone plugin //////////

.react-phone-number-input__icon {
  height: 12px !important;

  svg {
    vertical-align: top !important;
  }
}

.react-phone-number-input__row {
  @extend .input-group;
}

.react-phone-number-input__country {
  @extend .input-group-text;
  @extend .input-group-prepend;
}

.react-phone-number-input__input {
  height: calc(2.92rem + 2px) !important;
  border-radius: 0.3rem !important;
  padding: 0.75rem 20px 0.75rem 15px !important;
  border: 1px solid #e5e5e5 !important;
  border-bottom: 1px solid #e5e5e5 !important;
  transition: border 0.1s !important;
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.react-phone-number-input__country {
  margin-right: -1px !important;
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.not-found-page {
  height: calc(100vh - 100px);
  width: 100%;
}

.not-found-page-left-side {
  justify-content: center;
}

.not-found-page-right-side {
  align-items: center;
}

@include media-breakpoint-up(lg) {
  .not-found-page-left-side {
    justify-content: flex-end;
  }
  .not-found-page-right-side {
    align-items: flex-start;
  }
}

/////// devices ///////
.specification-block-folded {
  height: 270px;
  overflow-y: hidden;
}

.device-notes {
  opacity: 0.8;
  border-left: 3px solid rgb(255, 193, 7);
  border-radius: 3px;
  margin-left: -17px;
  margin-bottom: 2rem;
  padding-left: 17px;
  max-height: 450px;
  overflow: auto;

  span {
    color: $gray-500;
  }

  &:hover,
  &.edit {
    opacity: 1;
  }

  &:hover {
    cursor: pointer;
  }

  &.edit {
    textarea {
      max-height: 350px;
      overflow: auto;
      resize: none;
    }
  }
}

//NotificationFeed

.z-index-over-modal {
  z-index: 1080;
}

.notification-feed {
  .notification-card {
    transition: transform 0.05s linear;
  }

  .notification-card-active {
    transform: scale(1.03);
  }
}

.push-notification {
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  max-height: 7em;
}

.shown {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s linear, opacity 0.33s linear;
}

.hidden {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.33s linear 0s, visibility 0s linear 0.4s;
}

.pointer-none {
  pointer-events: none;
}

.pointer-all {
  pointer-events: all;
}

.opacity-gradient {
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
}

//Add organization page
// .add-org-no-trial-template {
//   grid-template-areas:
//     'a b'
//     'a c';
// }

//Kubernetes page
.kubernetes-container {
  .react-bootstrap-table-pagination {
    @extend .col-md-12;
    @extend .mx-auto;
  }
}

.tag-key-input {
  padding-left: 30px !important;
}

.tag-key-input-container::before {
  content: 'u-';
  position: absolute;
  left: 1rem;
  top: calc(50% - 0.75em);
  font-size: 1.5em;
  opacity: 0.7;
}

//Import Networks

.importable-networks-container {
  @extend .d-flex;
  @extend .flex-column;
  @extend .p-3;
  height: 340px;
  overflow-y: scroll;
}

//Text align
.txt-align-start {
  text-align: start !important;
}

.txt-align-left {
  text-align: left;
}

.no-strap-styling-check {
  position: unset;
  margin: 0;
}

.pointer-none {
  pointer-events: none;
}

///File Input
.custom-file-input-2 {
  color: transparent;
  width: 40px;
}

.custom-file-input-2::-webkit-file-upload-button {
  visibility: hidden;
}

.custom-file-input-2::before {
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  content: '+';
  display: inline-block;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  @extend .btn;
  @extend .btn-light;
}

.custom-file-input-2:hover::before {
  border-color: black;
}

.custom-file-input-2:active {
  outline: 0;
}

.bp-btn,
.btn-light,
.custom-file-input-2:before {
  border: none;
  background-color: #f5f5f5;
}

/// Paddings
.py-4_5 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

/// Borders

.border-radius-50 {
  border-radius: 50%;
}

.border-radius-0 {
  border-radius: 0 !important;
}

.row-border-radius-first {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.row-border-radius-last {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.flex-container {
  display: flex;
  overflow: hidden;
  box-sizing: border-box;

  .box {
    border: 1px solid #e5e5e5;
    cursor: pointer;
    margin-right: 10px;
    font-size: 14px;
  }

  .box_A {
    border-top: 3px solid $error-color;
  }

  .box_B {
    border-top: 3px solid #4eb79a;
  }

  .background-gradient {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    opacity: 0.1;
    right: 0;
  }

  .gradient-red {
    background: radial-gradient(
      93.31% 117.22% at 50% 117.22%,
      rgba(255, 255, 255, 0) 54.69%,
      rgba(242, 95, 92, 0.4) 100%
    );
  }

  .gradient-green {
    background: radial-gradient(
      93.31% 117.22% at 50% 117.22%,
      rgba(255, 255, 255, 0) 54.69%,
      rgba(78, 183, 154, 0.4) 100%
    );
  }

  .gradient-opacity-transition {
    transition: 0.3s;
  }

  .gradient-opacity-transition:hover {
    opacity: 0.3;
  }
}

.padding20 {
  padding: 20px;
}

.tabs-container {
  border: 1px solid #e5e5e5;

  .btn-group {
    width: 100%;
    margin: 0;
  }

  .btn {
    padding: 0.55rem 1.15rem;
    flex-grow: 1;

    &.btn-primary {
      min-width: 45px;
      text-align: center;
    }
  }

  &.borderless-bottom {
    border-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  @include media-breakpoint-up(lg) {
    &.lg {
      .btn {
        padding: 1.4rem 1.15rem;
        height: 60px;
        font-size: 14px;

        &.active {
          font-weight: bold;
        }
      }
    }
  }
}

.cloud-link,
.cloud-link:hover,
.cloud-link:focus {
  text-decoration: none;
  color: #333;
}

.tab-container-sm {
  > {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  button:first-child {
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
  }

  button:last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  .tab {
    @extend .btn;
    @extend .btn-white;
    margin: 0;
    border-radius: 0;
    height: 100%;
    width: auto !important;
  }

  .tab-selected {
    @extend .btn;
    @extend .btn-blue;
    margin: 0;
    border-radius: 0;
    height: 100%;
    color: $primary;
    width: auto !important;

    &:focus {
      background-color: rgba(84, 138, 243, 0.2) !important;
      border: 1px solid $primary !important;
      color: $primary;
    }
  }
}

.tab-container-md {
  > {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tab {
    @extend .btn;
    @extend .btn-white;
    border: none;
    border-radius: 0;
    font-size: small;
  }

  .tab-selected {
    @extend .btn;
    background: rgba(203, 219, 251, 0.3);
    color: $blue-dark;
    border-bottom: 2px solid $blue-dark;
    border-radius: 0;
    font-size: small;

    &:hover {
      color: $blue-dark;
    }
  }
}

.tab-container-lg {
  > {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tab {
    @extend .btn;
    @extend .btn-white;
    border-radius: 0;
    border: none;
    height: 50px;
  }

  .tab-selected {
    @extend .btn;
    @extend .btn-blue-filled;
    border-radius: 0;
    height: 50px;
  }
}

.cloud-card-body {
  padding: 50px 20px;
}

.cloud-item-card-body {
  padding: 20px;
}

.cloud-card-footer {
  padding-bottom: 10px;
  padding-left: 20px;
}

.cloud-card-status {
  margin-left: 5px;
  margin-top: 2px;
}

.cloud-hr {
  margin-top: 0.7rem;
  margin-bottom: 0.8rem;
}

.new-cloud-img {
  margin-top: -4px;
}

.request-cloud-btn {
  width: 60%;
  position: absolute;
  top: 90px;
  left: 20%;
}

.entity-block-table-container {
  border-top-right-radius: 0;
  border-top-left-radius: 0;

  .selection-cell-header {
    width: 30px !important;
    padding-right: 0 !important;
    padding-left: 1rem !important;

    label {
      &:before {
        border-radius: $border-radius-sm;
        border: 1px solid #ccc !important;
        width: 20px;
        height: 20px;
      }
    }
  }

  .selection-cell {
    width: 30px !important;
    padding-right: 0 !important;
    padding-left: 1rem !important;

    input {
      height: 0;
    }

    label {
      width: 30px;
      margin-bottom: 0.8rem;

      &:before {
        border-radius: $border-radius-sm;
        border: 1px solid #ccc !important;
        width: 20px;
        height: 20px;
      }
    }
  }

  .resource-row:last-child,
  .resource-row:nth-child(10),
  .resource-row:nth-child(12) {
    border-top: 2px solid #eee !important;
  }

  .border-top-dark {
    border-top: 2px solid #3a3535 !important;
  }
}

.assigned-devices-card .badge {
  margin-bottom: 10px;
  margin-right: 10px;
}

.edit-access-btn {
  float: right;
  padding: 0.45rem 1.15rem;
}

.whitelist-badge {
  color: #333;
  background-color: rgba(203, 219, 251, 0.5);
  margin-bottom: 10px;
  margin-right: 10px;
}

.resources-usage-card {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.hv-type-badge {
  padding: 5px 12px;
  border-radius: 20px;
  font-weight: bold;
}

.hv-type-badge-public {
  background-color: rgba(60, 122, 241, 0.2);
  color: $blue-dark;
}

.hv-type-badge-private {
  background: rgba(237, 177, 0, 0.2);
  color: #b48745;
}

.hv-type-badge-replica {
  margin-top: 3px;
  background: #e4daf6;
  color: #4a45a1;
}

.hv-type-badge-free {
  margin-top: 3px;
  background: #70d2bf;
  color: #333333;
}

.hv-type-badge-mc-replica {
  margin-top: 3px;
  background: #e4daf6;
  color: #8f56b6;
}

.hv-type-badge-streched {
  margin-top: 3px;
  background: #e4daf6;
  color: #9570dd;
}

.hv-type-badge-1 {
  background-color: rgba(91, 111, 227, 0.2);
  color: #5670eb;
}

.hv-type-badge-6 {
  background-color: rgba(114, 113, 214, 0.2);
  color: #966fdd;
}

.striped-list-prefix {
  width: 100px;

  & > div {
    height: 50px;
    padding: 0px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
}

.striped-list {
  @extend .striped-list-prefix;
  width: 240px;
  border-radius: 3px;
  border: 1px solid $gray-200;
  color: $black;

  div {
    &:nth-child(2n) {
      background: $gray-200;
    }
  }

  & > div {
    justify-content: space-between;
  }

  &-blue {
    @extend .striped-list;
    border: 1px solid #cbdbfb;
    background: rgba(156, 186, 247, 0.15);

    div {
      &:nth-child(2n) {
        background: #cbdbfb;
      }
    }
  }
}

.resource-block-note {
  background: rgba(78, 183, 154, 0.1);
  border: 1px solid rgba(192, 229, 219, 0.5);
  border-top-left-radius: 2rem;
  border-bottom-left-radius: 2rem;
  color: #39a587;
  width: 94px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.firewall-network-select-container label {
  max-width: 20rem;
}

.network-block {
  position: relative;
  background: rgba(203, 219, 251, 0.2);
  border: 1px solid #cbdbfb;
  border-radius: 4px;

  .arrows {
    position: absolute;
    width: 100%;
    top: -26px;
    left: 0;
  }
}

#billing-address-modal {
  .col-3,
  .col-6 {
    padding-left: 10px;
    padding-right: 10px;
  }
}

#detailed-report-resources-modal.modal-dialog {
  max-width: 70vw;
}

.card button.customize-experience-tab {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  border-radius: 5px;
  align-items: center;
  width: 30% !important;
}

.form-group.customize-experience-div {
  padding-left: 1.2rem;
}

.form-group.customize-experience-div label {
  padding: 0 0.5rem;
}

.delete-billing-plan-btn {
  position: absolute;
  top: 2px;
  right: 5px;
}

.navbar-toggler {
  margin-left: 22px;
}

.navbar-toggler-in-header {
  margin-left: 0;
}

.navbar-toggler i {
  color: #fff;
}

.navbar-toggler-in-header i {
  color: #333;
}

body:not(.sidebar-open) .navbar-toggler-img {
  display: none;
}

.user-story-icon {
  width: 30px;
  height: 30px;
}

.user-story-card {
  max-height: 400px;
  overflow-y: auto;
}

.form-error-message {
  width: 100%;
  font-size: 80%;
  color: $error-color;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

.react-daterange-picker__wrapper {
  padding-left: 8px;
}

.add-dns-container {
  max-width: 65rem;
  margin-top: 10rem;
}

.bage-group {
  div:first-child {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  .delete-ending {
    @extend .btn;
    @extend .btn-blue;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    height: 100%;
    @extend .font-gray;

    &:hover {
      background-color: $danger !important;
      color: $light;
    }
  }

  .add-ending {
    @extend .delete-ending;

    &:hover {
      background-color: $success !important;
      color: $light;
    }
  }
}

.user-story-row {
  &:hover {
    background: rgba(203, 219, 251, 0.2);
  }
}

.notification-dropdown-menu {
  max-height: 44rem;
  overflow: auto;
}

.notification-dropdown-item-container {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #333333;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.notification-card,
.notification-card-active {
  background: rgba(203, 219, 251, 0.2);
}

.notification-card-text {
  font-size: 14px;
}

.notification-card-date {
  position: absolute;
  bottom: 5px;
  right: 5px;
  color: #333;
  color: #999;
  font-size: 12px;
}

.blocked-menu-block {
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

.blocked-menu-sub-block {
  width: 100%;
  height: 20rem;
  margin-top: 22rem;
  background-color: rgba(0, 0, 0, 0.6);
}

.blocked-menu-sub-block p {
  vertical-align: middle;
  font-size: 20px;
  text-align: center;
  color: #fff;
}

.load-balancer-url-input::placeholder {
  color: hsl(0, 0%, 50%);
  font-weight: bold;
}

.card-container {
  margin-bottom: 1.5rem;
}

.loading-circle {
  height: 1.3rem;
  width: 1.3rem;
  background: url(../../images/blue-loading.svg);
  background-size: fill;
  background-position: center;
}

.loading-circle-danger {
  height: 1.3rem;
  width: 1.3rem;
  background: url(../../images/red-loading.svg);
  background-size: fill;
  background-position: center;
}

.storage-item-div {
  border: 2px solid;
  border-radius: 5px;
  padding: 5px 10px;
  display: inline-block;
}

.rc-time-picker-panel {
  position: fixed !important;
}

.phone-input-styled {
  display: flex !important;

  & > input {
    border: none;
  }
}

.input__danger-message {
  height: 4px;
}

.input__muted-message {
  color: #808080;
}

.photo-upload-header {
  border-bottom: 1px solid #e5e5e5 !important;
}

.photo-container {
  &__body {
    border-bottom: 1px solid #e5e5e5 !important;
    background: #333;
  }

  &__photo {
    max-height: 320px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;

    img {
      max-height: 300px;
      max-width: 100%;
      object-fit: contain;
    }
  }
}

.photo-edit__body {
  height: 80px;
}

.label-upload-photo {
  border: 1px solid #d4d4d4;
  border-radius: 2.6px;
  padding: 0.8rem 0.4rem;
  margin-bottom: 0;

  &:hover {
    background: #f0f0f0;
    cursor: pointer;
  }
}

.no-display {
  display: none;
}

.crop-control-block {
  position: absolute;
  right: 0;
  bottom: 5px;
  display: flex;
}

.apply-crop-button {
  background: #fff;
  z-index: 1;
  padding: 0.3rem;
  font-size: 10px;
  margin-right: 4px !important;
  margin-bottom: 3px;

  img {
    filter: none;
  }
}

.crop-image-container {
  max-height: 320px;
  max-width: 100%;

  img {
    max-height: 320px;
    max-width: 100%;
    object-fit: contain;
    position: relative;
  }
}

.footer-buttons-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 1rem;

  button {
    margin-left: 10px;
  }
}

.content-dynamic-justify {
  justify-content: space-around;
}

.table-left-columns {
  tr {
    td:first-child {
      padding-left: 2rem;
      width: 9rem;
    }
  }

  &.wide {
    tr {
      td:first-child {
        padding-left: 2rem;
        width: 14rem;
      }
    }
  }
}

.dashboard-flex-container {
  justify-content: space-between;
  align-content: center;
  margin-top: 0.5rem;

  button {
    margin-left: 1rem;
  }

  padding: 0 !important;
}

.typical-name-display {
  word-break: break-all;
}

.dashboard-widget-dropdown {
  margin-left: -40px;
  margin-top: 20px !important;
}

.monitoring-block {
  width: 28rem;
}

.cluster-border-right {
  border-right: none;
}

.centered-flex-container {
  justify-content: center;

  div {
    margin-bottom: 1rem;
  }
}

.flex-node-component-green {
  @include flex-node-component(rgba(78, 183, 154, 0.1), rgba(78, 183, 154, 0.3));
  @include media-breakpoint-up(md) {
    & > div:first-child {
      border-right: 1px solid rgba(78, 183, 154, 0.3);
      border-bottom: none;
    }
    .orand-connector {
      display: block;
    }
  }
}

.flex-node-component-pink {
  @include flex-node-component(rgba(242, 95, 92, 0.1), rgba(242, 95, 92, 0.3));
  margin-top: 1rem;
  @include media-breakpoint-up(md) {
    margin-top: 0.5rem;
    & > div:first-child {
      border-right: 1px solid rgba(242, 95, 92, 0.3);
      border-bottom: none;
    }
    .orand-connector {
      display: block;
    }
  }
}

@include media-breakpoint-up(sm) {
  .dashboard-flex-container {
    justify-content: flex-end;
    margin-top: 0;
  }

  .content-dynamic-justify {
    justify-content: flex-start;
  }

  .monitoring-block {
    width: 50%;
    padding-left: 1rem;
  }

  .firewall-network-select-container label {
    max-width: 25rem;
  }

  .centered-flex-container {
    justify-content: flex-start;
  }

  .label-upload-photo {
    padding: 0.8rem 0.7rem;
  }
}

@include media-breakpoint-up(md) {
  .cluster-border-right {
    border-right: 1px solid #eee;
  }

  .flex-group {
    flex-wrap: nowrap;

    & > div {
      flex-grow: 1;
      width: auto;

      &:first-child {
        flex-grow: 2;
      }
    }
  }

  .mt {
    margin-top: $md-padding;
  }

  .mt-sm {
    margin-top: 0;
  }

  .mb {
    margin-bottom: $md-padding;
  }

  .server-list-header {
    align-items: flex-end;
    flex-direction: row;
    border: none;
    padding: 0;
    background-color: transparent;

    .select-container {
      max-width: 300px;
      min-width: 200px;
    }

    & > .btn {
      max-width: 300px;
      margin-top: 0;
      margin-left: 1rem;
    }

    .input-group {
      max-width: 300px;
      margin-right: auto;
    }

    .search-group {
      max-width: 200px;
      margin-right: auto;
    }
  }

  .cockpit-part {
    margin-top: 0;
    padding: 8px;
    border: 1px solid $gray-300;
    border-top-color: transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background-color: $white;
  }

  .info-block-borderless {
    &:not(:last-child) {
      .ib-item {
        border-bottom: none;
      }
    }

    &:last-child {
      .ib-item {
        &:last-child {
          padding-bottom: 1.2rem;
        }

        &:not(:last-child) {
          border-bottom: none;
        }

        &:first-child {
          padding-top: 1.2rem;
        }
      }
    }

    .ib-item {
      justify-content: left;
      padding: 1.2rem;

      i {
        margin-right: 20px;
        order: 0;
      }
    }
  }
}

.custom-dropdown-overflow {
  overflow: inherit !important;
}

.bootstrap-table-row {
  vertical-align: top !important;
  align-items: flex-start !important;
  padding-top: 2rem !important;
}

.bootstrap-table-row-nb {
  vertical-align: top !important;
  align-items: flex-start !important;
  padding-top: 1rem !important;
}

.base-icons {
  &:hover {
    cursor: pointer;
    color: #333;
  }
}

.hide-table-when-load {
  opacity: 0;
}

.custom-table-row {
  min-width: 40rem;
  padding-left: 1rem;
}

#ssh-keys-modal {
  max-width: 650px;

  .modal-body {
    height: 300px;
    overflow: scroll;
    word-wrap: break-word;
  }

  .react-bootstrap-table {
    border: none;
  }
}

.bluring-overlay-container {
  width: 100%;
  height: 100%;
  background-size: cover;
}

.bluring-overlay {
  width: 100%;
  height: 100%;
  background-size: cover;
  -webkit-filter: blur(6px);
  -moz-filter: blur(6px);
  -ms-filter: blur(6px);
  -o-filter: blur(6px);
  filter: blur(6px);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}

#start-onboarding-modal {
  margin: 0;
  width: 100% !important;
  max-width: none !important;
  height: 100%;

  .modal-dialog {
    margin: 0;
    padding: 0;
  }

  .modal-content {
    margin: 0;
    padding: 0;
    border: 0;
    padding: 50px 70px;
    height: auto;
    min-height: 100%;
    border-radius: 0;
    background-color: $light;
  }
}

#start-onboarding-card {
  margin: 5rem;
  padding: 50px 70px;
  background-color: #fff;
}

#city-of-server-img {
  width: 60%;
  left: 40%;
  position: absolute;
  top: -5rem;
}

.onboarding-row {
  padding-top: 150px;
  margin-left: 0px;
  margin-right: 0px;
}

.onboarding-trial-select-card {
  h3 {
    line-height: 40px;
  }

  p {
    line-height: 25px;
  }
}

.onboarding-trial-select-card .card-header {
  height: 63px;
  border: none;
}

.onboarding-trial-select-card .card-body {
  padding: 2rem;
}

.onboarding-wifi-div {
  width: 50px;
  height: 50px;
  padding-top: 10px;
  border-radius: 40px;
  position: absolute;
  top: 35px;
  border: 3px solid #fff;

  img {
    width: 25px;
    height: 25px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}

.onboarding-wifi-div.onboarding-tick-wifi-div {
  background-color: #2fc48a;
}

.onboarding-wifi-div.onboarding-no-wifi-div {
  background-color: #777e90;
}

.onboarding-overlay-forward-component {
  position: absolute;
  left: 50%;
  top: calc(50% - 30px);
  z-index: 100;
  font-weight: bold;
}

.onboarding-overlay-forward-component .onboarding-overlay-forward-component-sub {
  position: relative;
  left: -60%;
  top: -50%;
  width: 120%;
  padding: 20px 30px;
  background: #fff;
  border: 1px solid #e5e5e5;
}

.onboarding-payment-card {
  height: 225px;
}

.onboarding-os-selector .form-check-label-btn,
.onboarding-os-selector .form-check-label-btn-selected {
  height: 160px;
}

.network-type-select-item {
  height: 150px;

  .form-check-label-btn {
    display: inline-block !important;
  }

  .network-type-select-item-name {
    padding-top: 7.5px;
    padding-bottom: 7.5px;
    font-weight: bold;
    font-size: 1.2rem;
    border-bottom: 1px solid #e5e5e5;
  }

  img {
    width: 60%;
    margin-top: 15px;
  }
}

.network-type-select-item.checked {
  .form-check-label-btn {
    border: 2px solid #548af3 !important;
    background-color: #fff !important;
  }

  .network-type-select-item-name {
    background-color: rgba(203, 219, 251, 0.3);
    color: $blue-dark;
  }
}

.onboarding-footer-container {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.onboarding-footer-summary {
  width: 100%;
  height: 80px;
  background: #fff;
  border: 1px solid #e5e5e5;
}

.onboarding-footer-submit {
  width: 100%;
  height: 60px;
  background: linear-gradient(90deg, #3da99c 0%, #00d57b 100%);
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  border: none;
}

.onboarding-logout-button {
  position: fixed;
  top: 15px;
  right: 15px;
  width: 7%;
}

.onboarding-logo-overlay {
  position: fixed;
  top: 0;
  left: calc(50% - 55px);
  background-color: white;
  padding: 15px 20px;
  border: 1px solid #e5e5e5;
  z-index: 300;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.flexible-nodes {
  width: 100%;
  border-left: none;
  border-right: none;
  border-bottom: none;
  padding: 1rem;
}

.network-selection-container {
  margin-bottom: 0.8rem;

  & > div {
    margin-bottom: 0;
  }

  img {
    margin-right: 1rem;
  }

  p {
    margin: 0;
    opacity: 0.8;
  }

  p:nth-child(2) {
    margin-top: 0.2rem;
  }

  label {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    width: 100%;
    padding: 1.2rem;

    &:hover {
      cursor: pointer;
    }
  }

  &:hover {
    cursor: pointer;

    p {
      opacity: 1;
    }
  }

  &__selected {
    border: 1px solid rgba(70, 100, 233, 0.5);
    background-color: rgba(84, 138, 243, 0.2);
    color: rgb(70, 100, 233);

    p {
      opacity: 1;
    }
  }

  &__disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.ips-example-card {
  padding: 1rem;
  background: #fff3cd;
  border: 1px solid rgba(255, 193, 7, 0.5);
  border-radius: 3px;
}

.card-no-upper-border {
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.card-no-bottom-border {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.basic-shadow {
  box-shadow: 0 5px 7px rgba(0, 0, 0, 0.2);
}

.drag-n-drop {
  border: 1px solid #cbdbfb;
  background: rgba(156, 186, 247, 0.15);
  border-radius: 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  margin-bottom: 10px;

  .row {
    align-items: center;

    p {
      margin-bottom: 0;
    }
  }
}

.drag-n-drop-files {
  ul {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;

    li {
      list-style: none;
      margin-right: 10px;
      margin-bottom: 10px;

      &:last-child {
        margin-right: 0;
      }

      .delete-uploaded-file {
        span {
          margin-right: 7px;
        }
      }
    }
  }
}

.note-text {
  font-size: 11px;
  margin-top: 5px;
  color: #525252;
}

.single-line {
  position: relative;

  .ip-validation-error {
    border: 1px solid red;
  }

  button {
    position: absolute;
    z-index: 0;
    bottom: 0;
    right: 0;
  }
}

.w-32 {
  width: 32%;
}

.screen-height {
  .react-bootstrap-table {
    height: calc(100vh - 320px);
  }
}

.hierarchy {
  background-color: white;
  border-bottom: 1px solid #e5e5e5;
  border-left: 1px solid #e5e5e5;
  position: relative;
  transition: margin 0.3s;

  button {
    transform: rotate(-90deg);
  }

  button[data-open='true'] {
    transform: rotate(0);
  }

  &.shift {
    margin-left: -10px;
  }

  &.highlight {
    background-color: #d5e6fa;

    a {
      font-weight: 700;
      color: #1b376e !important;
    }
  }
}

.hierarchy-children {
  display: none;

  .hierarchy {
    background-color: #f2f8ff;

    &.highlight {
      background-color: #d5e6fa;

      a {
        font-weight: 700;
        color: #1b376e !important;
      }
    }
  }
}

.hierarchy.shift + .hierarchy-children,
.hierarchy-children:has(.highlight) {
  display: block;
  border-left: 2px solid #4665e9;
}

.hierarchy-items .hierarchy-items {
  margin-bottom: 5px;
  margin-left: 20px;
}

th[data-row-selection] {
  width: 30px;
}

.forwarding-rules-table {
  .table {
    td {
      padding: 0.4rem;
    }

    td:first-child {
      padding-left: 1.5rem;
    }

    td:last-child {
      padding-right: 1.5rem;
    }

    input {
      padding: 1rem !important;
    }

    .react-select-portaled__input {
      input {
        padding: initial !important;
      }
    }
  }
}

.queue-jobs-list {
  list-style: none;
  padding-left: 5px;

  li {
    padding: 3px;
    margin-bottom: 2px;

    img {
      margin-right: 5px;
      width: 16px;
    }

    p {
      padding: 15px;
      background: #f25f5c4d;
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btn-console {
  padding: 5px 9px;
}

.text-editor-style {
  border: 1px solid #f1f1f1;
  padding: 0 15px;
  min-height: 200px;
}

.upload-device-button {
  width: 250px !important;
}

.progress {
  background-color: #d3dff8;
  height: 1.4rem;
}

.progress .progress-bar {
  background-color: #86abf6;
}

.no-service-user {
  height: 38px;
  display: flex;
  align-items: center;
  color: $danger;
}

.download-btn-no-text {
  position: relative;
  min-width: 36px;
  height: 36px;
}

.download-btn-no-text .fa-download {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 14px;
  color: #4c4c4c;
}

.download-btn-no-text .lds-facebook {
  position: absolute;
  top: 7px;
  left: -6px;
}

.mail-event-delivered {
  color: #439b73;
}
.mail-event-accepted {
  color: #8bc1a9;
}
.mail-event-opened {
  color: #006ce6;
}
.mail-event-clicked {
  color: #ff8f00;
}
.mail-event-permanent-failed {
  color: #cd5053;
}
.mail-event-temporary-failed {
  color: #e6a7a9;
}

.color-black {
  color: $gray-900 !important;
}

.color-grey {
  color: $gray-500 !important;
}

.color-primary {
  color: $primary !important;
}

.text-dotted {
  text-decoration: underline;
  text-decoration-style: dotted;
}

@keyframes wiggle {
  0%,
  7% {
    transform: rotateZ(0);
  }
  15% {
    transform: rotateZ(-15deg);
  }
  20% {
    transform: rotateZ(10deg);
  }
  25% {
    transform: rotateZ(-10deg);
  }
  30% {
    transform: rotateZ(6deg);
  }
  35% {
    transform: rotateZ(-4deg);
  }
  40%,
  100% {
    transform: rotateZ(0);
  }
}

.currentOwner {
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 4px;
  padding: 8px;
  min-height: 38px;
}

.changeOwnerArrow {
  margin: 0 13px;
  padding-top: 33px;
}

.snapshot-count {
  background: #e69602;
  border-radius: 10px;
  font-size: 11px;
  padding: 0 6px;
  margin-right: 4px;
  color: white;
}

.release-ip {
  &:hover {
    cursor: pointer;
  }
}

.no-row-hover {
  tr:hover {
    cursor: default !important;
  }
}

.hr-cloud-gray {
  background-color: #777e90;
  width: 55px;
  margin: 28px 0 0 0;
  padding: 0;
}

.hr-cloud-blue {
  background-color: #4664e9;
  width: 55px;
  margin: 28px 0 0 0;
  padding: 0;
}

.text-orange {
  color: #ff8707 !important;
}

.totpNumber {
  text-align: center;
  line-height: 50px;
  width: 50px;
  height: 50px;
  font-size: 2rem;
}

.totpNumber::placeholder {
  font-weight: 100;
}

.totpBorderLeft {
  border-left: 1px solid #dee2e6;
  border-right: 0;
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.totpBorderCenter {
  border-left: 1px solid #dee2e6;
  border-right: 0;
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
}

.totpBorderRight {
  border: 1px solid #dee2e6;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.link-search-item {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.link-search:hover,
.link-focus {
  margin: 0;
  border-radius: 0;
  height: 100%;
  color: $primary;
  background-color: rgba(84, 138, 243, 0.2) !important;
  width: auto !important;
}

.input-spotlight {
  input:focus {
    border: solid $primary 1px;
  }
}

.date-picker-w-100 .react-datepicker-wrapper {
  width: 100%;
}

.talos-container {
  height: calc(100vh - 100px);
}

.talos-pool-name-input {
  border: none;
  font-size: 1.5rem;
  font-weight: bold;
}

.talos-pools {
  height: calc(100vh - 240px);
  overflow-y: scroll;
  overflow-x: hidden;
  align-self: flex-start;
  margin-top: 35px;
}

.talos-edit-pool {
  border-radius: 35px;
  border: 1px solid var(--ui-gray-outer-borders, #e5e5e5);
  background: white;
  padding: 6px 10px;
  margin-left: 10px;
}

.talos-delete-pool {
  border-radius: 35px;
  border: 1px solid rgba(242, 95, 92, 0.3);
  background: #feefef;
  padding: 6px 10px;
  margin-left: 10px;
}

.talos-main-button {
  position: absolute;
  bottom: -20px;
  background: linear-gradient(90deg, #339f92 0%, #00ca71 100%);
  width: 100%;
  padding: 10px 0;
  text-align: center;
  color: white;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  border: none;

  &:disabled {
    background: linear-gradient(90deg, #92aba8 0%, #9eccb8 100%);
    border-color: transparent;
    color: #eaeaea;
    cursor: initial;
  }
}

.talos-price {
  position: absolute;
  bottom: 24px;
  width: 100%;
  border-top: 1px solid lightgray;
}

.talos-stepper {
  position: absolute;
  top: 0;
  width: 100%;
}

.stepper-text {
  font-size: 14px;
  font-weight: 500;
}

.opacity-50 {
  opacity: 0.5;
}

.link-search:hover,
.link-focus {
  margin: 0;
  border-radius: 0;
  height: 100%;
  color: $primary;
  background-color: rgba(84, 138, 243, 0.2) !important;
  width: auto !important;
}

.input-spotlight {
  input:focus {
    border: solid $primary 1px;
  }
}

.date-picker-w-100 .react-datepicker-wrapper {
  width: 100%;
}

.multi-input {
  min-height: 40px;
  border: 1px solid #cccccc;
  border-radius: 3px;
  padding: 5px 7px 5px 7px;
  display: flex;
  flex-wrap: wrap;
}

.multi-input-item {
  background-color: hsl(0, 0%, 90%);
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  box-sizing: border-box;
  font-size: 85%;
  padding: 3px 3px 3px 6px;
  margin-top: 2px;
  margin-bottom: 2px;
}

.multi-input-input-field {
  border: 0;
  background-color: hsl(0, 0%, 90%);
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  box-sizing: border-box;
  font-size: 85%;
  padding: 3px 3px 3px 6px;
  margin-top: 2px;
  margin-bottom: 2px;
}

.multi-input-placeholder {
  font-size: 85%;
  padding-top: 6px;
  color: $gray-400;
}

.multi-input-input-field {
  border: 0;
  background-color: hsl(0, 0%, 90%);
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  box-sizing: border-box;
  font-size: 85%;
  padding: 3px 3px 3px 6px;
  margin-top: 2px;
  margin-bottom: 2px;
}

.multi-input-button {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  padding: 0 7px;
  background-color: hsl(0, 0%, 90%);
  font-size: 85%;
  margin-top: 2px;
  margin-bottom: 2px;
}

.multi-input-button:hover {
  background-color: #ffbdad;
  color: #de350b;
}

.multi-input-input-field {
  border: 0;
  box-sizing: border-box;
  background-color: hsl(0, 0%, 90%);
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  font-size: 85%;
  padding: 3px 3px 3px 6px;
  margin-top: 2px;
  margin-bottom: 2px;
}

.cursor-not-allowed {
  pointer-events: auto;
  cursor: not-allowed;
}

.link-gray {
  color: #6a6c70;
}

.device-power {
  border: 0;
  border-radius: 3px;
  width: 39px;
  height: 20px;
}

.device-power i {
  font-size: 9px;
}

.device-power span {
  font-size: 11px;
  font-weight: bold;
  margin-left: 4px;
}

.power-on {
  background: #4eb79a33;
  color: #4eb79a;
}

.power-off {
  background: #f2f2f2;
  color: #777e90;
}

.btn-table-secondary {
  text-decoration: none;
  border: 1px solid #b3cbf9;
  border-radius: 4px;
  background: #cbdbfb;
  padding: 5px 14px 5px 14px;
  color: black;
  font-weight: normal;
  font-size: 1rem;
}

.btn-table-secondary:hover {
  text-decoration: none;
  color: black;
  background: #b3cbf9;
}

.activity-badge {
  border-radius: 3px;
  width: 82px;
  height: 20px;

  &.badge-enabled {
    background: #4eb79a33;
    color: #4eb79a;
  }

  &.badge-disabled {
    background: #fedcdb;
    color: #f25f5c;
  }

  i {
    font-size: 9px;
  }

  span {
    font-size: 11px;
    font-weight: bold;
    margin-left: 4px;
  }
}

.provisioning {
  border: 2px dashed #4665e9;
  border-radius: 8px;
  width: 16px;
  height: 16px;
  animation: spin 4s linear infinite;
}

.btn-nostyle,
.btn-nostyle:focus,
.btn-nostyle:hover,
.btn-nostyle:active {
  padding: 0;
  margin: 0 !important;
  border: none;
  cursor: pointer;
  color: black;
  text-decoration: none;
  white-space: nowrap;
  background: transparent none !important;
  font-weight: normal;
  font-size: 1rem;
  text-align: left;
}

.update-button {
  width: 82px;
}

.allowed-domains {
  max-height: 326px;
  overflow-y: auto;
}
