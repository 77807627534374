.auth-block {
  width: 100vw;
  height: 100vh;
  background: url('../../images/login_bg.png') center center no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 15vh;

  &:after {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    z-index: 0;
  }

  h1 {
    font-weight: 200;
    font-size: 28px;
    margin-bottom: 26px;
    padding-left: 0;

    strong {
      font-weight: bold;
    }
  }

  form:not(.was-validated) {
    .input-group > .form-control {
      border-right-color: transparent;
    }
    .input-group-text {
      border-left-color: transparent;
      .fas {
        font-size: 12px;
      }
    }
  }

  .create-account {
    margin-top: 10px;
    width: 100%;
    max-width: 360px;
    z-index: 10;
    font-size: 12px;
    height: 50px;
  }

  .form-check {
    .form-check-label {
      color: $gray-900;
      font-size: 12px;
      line-height: 20px;
    }
  }

  .demo {
    max-width: 500px !important;
  }

  .login {
    width: 100%;
    max-width: 360px;
    position: relative;
    z-index: 2;
    border: none;
    box-shadow: 0 30px 30px rgba(0, 0, 0, 0.1);

    .wrp {
      display: flex;
      justify-content: space-between;
      margin-top: 16px;

      a {
        font-size: 12px;
        color: $gray-900;
      }
    }

    p {
      margin: 4px 0 20px;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
    }

    .input-group {
      margin-bottom: 10px;
    }

    .input-group-text {
      min-width: 45px;
    }

    .btn {
      font-size: 12px;
      margin-top: 16px;
    }
  }

  .verify-account {
    text-align: center;

    img {
      padding-bottom: 20px;
    }

    h1 {
      font-weight: 700;
      font-size: 20px;
    }

    p {
      font-weight: 200;
    }

    button {
      right: 0;
    }
  }
}

.sign-in__container {
  flex: 1;
  height: 4.3rem;
  background: #fff !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e5e5e5;
  border-bottom: none;
  border-radius: 0 0 0 0.3rem;
  margin-top: 0 !important;
  img {
    width: 24px;
  }
  &:hover,
  &:active,
  &:focus {
    border: 1px solid #e5e5e5 !important;
    border-bottom: none !important;
    background: #f5f5f5 !important;
  }
  &____google-logo {
    &:hover {
      cursor: pointer;
    }
  }
  &____github-logo {
    &:hover {
      cursor: pointer;
    }
  }
}

.github-container {
  border-radius: 0 0 0.3rem 0;
  border-left: none;
  &:hover,
  &:active,
  &:focus {
    border-left: none !important;
  }
}
