.entity-form {
  width: 35%;
  min-width: 400px;
  height: calc(100vh - 62px);
  position: fixed;
  right: 0;
  top: 62px;
  transition: transform 0.3s ease, opacity 0.3s ease;
  transform: translateX(100%);
  opacity: 0;
  background-color: #f8f9ff;
  border-left: 1px solid #e4e4e4;
  padding: 1rem;
  z-index: 75;
}

.entity-form-open {
  transform: translateX(0);
  opacity: 1;
  overflow: scroll;
}

.summary-form {
  width: 35%;
  min-width: 400px;
  height: calc(100vh - 62px);
  position: fixed;
  right: 0;
  top: 62px;
  transition: transform 0.3s ease, opacity 0.3s ease;
  transform: translateX(100%);
  opacity: 0;
  background-color: #f8f9ff;
  border-left: 1px solid #e4e4e4;
  padding: 1rem;
  z-index: 75;
}

.summary-form-open {
  transform: translateX(0);
  opacity: 1;
  overflow: scroll;
}

.entity-form-close-button {
  all: unset;
  display: inline-block;
  position: absolute;
  top: 18px;
  right: 24px;
  font-size: 14px;
}

.architecture-form-block {
  background: #fff;
  box-shadow: 0 4px 20px 0 rgba(70, 100, 233, 0.05);
}

.architecture-form-block-header {
  background-color: #f2f7ff;
}

.bg-soft-blue {
  background-color: #e6f0ff;
}

.architecture-form-shadow {
  border: 1px solid #e5edfd;
  box-shadow: 0 4px 20px 0 rgba(70, 100, 233, 0.15);
}

.logs-blue-circle {
  background-color: #eceffd;
  color: #4664e9;
  border-radius: 50%;
  min-width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.node-button-group {
  padding: 0;
  min-width: 26px;
  margin-right: 3px;
}

.node-logs-scroll {
  max-height: 200px;
  overflow-y: scroll;
}

.node-logs-scroll::-webkit-scrollbar {
  width: 8px;
}

.node-logs-scroll::-webkit-scrollbar-thumb {
  background-color: #c4c4c4;
  border-radius: 4px;
}

.node-logs-scroll::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a0;
}

.trash-button-right {
  position: absolute;
  right: 10px;
  top: 5px;
  cursor: pointer;
}

.architecture-cloud {
  position: absolute;
  top: 43px;
  left: 110px;
}

.text-gray {
  color: #7a7a7a;
}

.btn-violet {
  background-color: #4664e9;
  color: #fff;
}

.btn-violet:hover {
  background-color: #3a54d6 !important;
  color: #fff !important;
}

.full-height {
  overflow-y: auto;
  flex-grow: 1;
  min-height: calc(100vh - 88px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.architecture-sticky-header {
  position: absolute !important;
  width: 100%;
  padding: 0 1.5rem 0 1.5rem;
  display: flex;
  justify-content: space-between;
  z-index: 50;
}

.architecture-sticky-button-wrapper {
  margin-top: auto;
  position: sticky;
  bottom: 0;
  z-index: 0;
}

.grayCircleButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background-color: #f8f9ff;
  border: 1px solid #e5edfd;
  border-radius: 50%;
  cursor: pointer;
  padding: 0;
}

.selector-no-border div {
  border: none;
}

.architecture-content {
  width: 100%;
  transition: width 0.3s ease;
}

.architecture-code-editor-wrapper {
  margin-right: auto;
  margin-left: auto;
  height: 100%;
  border: 1px solid lightgrey;
}

.layout {
  width: 100% !important;
  height: 90vh;
}

.graph-image-node {
  border: 1px solid lightgray;
  border-radius: 10%;
  padding: 0.5rem;
  min-width: 100px;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.graph-add-component {
  display: flex;
  justify-content: center;

  i {
    cursor: pointer;
    border-radius: 50%;
    border: 1px solid lightgray;
    z-index: 3;
    padding: 0.2rem;
    margin: 0.2rem;
  }
}

#popover-basic {
  z-index: 50;
}

@keyframes fadeAndScale {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.options-popover {
  position: absolute;
  top: -27px;
  left: 105% !important;
  background: white;
  border-radius: 8px;
  border: 1px solid #ddd;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  animation: fadeAndScale 0.1s ease-out forwards;
}

.options-popover::before {
  content: "";
  position: absolute;
  height: 15px;
  width: 15px;
  top: 41px;
  left: -2px;
  transform: rotate(45deg) translateX(-50%);
  background: white;
  border-width: 1px;
  border-style: solid;
  border-color: transparent transparent #ddd #ddd;
}

.add-nodes-popover {
  position: absolute;
  top: 135px;
  background: white;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #ddd;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  animation: fadeAndScale 0.1s ease-out forwards;
}

.add-nodes-popover::before {
  content: "";
  position: absolute;
  height: 15px;
  width: 15px;
  top: -1px;
  left: 50%;
  transform: rotate(45deg) translateX(-50%);
  background: white;
  border-width: 1px;
  border-style: solid;
  border-color: #ddd transparent transparent #ddd;
}

.grayOnHover {
  &:hover {
    background-color: #cfcfcf;
  }
}



