//////// Layout///////

html,
body,
.wrapper,
.sidebar,
.rs-wrapper,
.main-wrapper {
  height: 100%;
}

body {
  // when modal opens it adds right padding to body (12px). Seems it is a Bootstrap glitch
  padding-right: 0 !important;
  &.sidebar-open {
    overflow: hidden;

    .sidebar {
      visibility: visible;
      transition: transform 0.5s;
      overflow-y: scroll;
      transform: translate3d(0, 0, 0);

      .navbar-nav {
        .nav-item {
          .nav-link {
            width: 100%;

            i.fas,
            i.fab,
            i.far {
              margin-right: 8px;
            }
          }

          @include media-breakpoint-up(md) {
            .nav-link {
              text-align: left;
            }
          }
        }
      }

      /////////// Submenu ///////////
      .submenu-link {
        position: relative;
        @extend .fas;
        @extend .fa-chevron-down;
        font-family: $font-family-sans-serif;
        font-weight: normal;

        &:before {
          font-family: 'Font Awesome 5';
          font-weight: 900;
          position: absolute;
          top: 18px;
          right: 16px;
          font-size: 8px;
        }

        &.open {
          @extend .fa-chevron-up;
        }
      }
      .submenu {
        margin-bottom: 0;
        list-style: none;
        padding: 0;

        .nav-item {
          .nav-link {
            padding: 10px 20px;
          }

          &:last-child {
            .nav-link {
              padding: 10px 20px 20px 20px;
            }
          }

          &:first-child {
            .nav-link {
              padding: 20px 20px 10px 20px;
            }
          }
        }
      }
    }

    .wrapper {
      .wrapper-after {
        width: 100%;
        height: 100%;
        opacity: 1;
        transition: opacity 0.5s;
        z-index: 100;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &.sidebar-open {
      overflow: initial;

      .main-header {
        width: calc(100% - 285px);
        margin-left: 285px;
      }

      .sidebar {
        visibility: visible;
        transition: all 0.3s ease-in-out;
        overflow-y: initial;
        min-width: 285px;
        width: 285px;
        overflow-x: hidden;
        position: fixed;
        height: 100vh;

        #theme-toggler,
        #updates-widget {
          display: inline-block;
        }

        .navbar-nav {
          .nav-item {
            &.heading {
              display: inline-block;
            }
            .nav-link {
              width: 100%;

              span {
                display: inline-block;
              }
            }
          }
        }
      }

      .wrapper {
        transform: none;

        .wrapper-after {
          width: 0;
          height: 0;
          opacity: 0;
        }

        .rs-wrapper {
          width: calc(100% - 285px);
          margin-left: 285px;
        }
      }
    }
  }
  @include customstyled-scrollbar;
}

.main-wrapper {
  overflow: hidden;
  position: relative;
}

.wrapper {
  display: flex;
  left: 0;
  transition: transform 0.5s;
  min-height: 100vh;
  z-index: 99;
  position: relative;

  .wrapper-after {
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    background: rgba(0, 0, 0, 0.3);
    opacity: 0;
    transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s;
  }
}

.rs-wrapper {
  width: 100%;
  overflow-y: scroll;
  min-height: 100vh;
}

.main-header {
  height: 39px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  transition: all 0.3s ease-in-out;
}

@include media-breakpoint-up(sm) {
  .main-header {
    height: 60px;
  }
}

.content-wrapper {
  padding-top: $header-height - $xs-padding;
  padding-bottom: $xs-padding;
  position: relative;
}

.r-side {
  padding-top: $xs-padding;
}

.sidebar {
  height: 100%;
  min-width: 80%;
  transform: translate3d(-100%, 0, 0);
  position: fixed;
  overflow-y: auto;
  top: 0;
  left: 0;
  visibility: hidden;
  transition: all 0.5s;
  z-index: 100;

  .sidebar-header {
    height: $header-height;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

// Media query for up 769px screens
@include media-breakpoint-up(md) {
  .sidebar {
    min-width: 30%;
  }

  .content-wrapper {
    padding-top: $header-height + $md-padding;
    padding-bottom: $md-padding;
  }

  .r-side {
    padding-top: 0;
    padding-left: $xs-padding !important;
  }

  .l-side {
    padding-right: $xs-padding !important;
  }
}

// Media query for up 992px screens
@include media-breakpoint-up(lg) {
  .sidebar,
  .rs-wrapper,
  .main-wrapper {
    height: auto;
  }

  .main-wrapper {
    overflow: visible;
    position: relative;
  }

  .main-header {
    width: calc(100% - 60px);
    margin-left: 60px;
  }

  .sidebar {
    width: 60px;
    height: 100vh;
    min-width: 60px;
    transform: none;
    position: fixed;
    top: 0;
    left: 0;
    visibility: visible;
    transition: all 0.3s ease-in-out;
    z-index: 100;
    overflow-x: hidden;

    .sidebar-content-wrapper {
      width: 285px;
      height: 100%;
    }

    #theme-toggler,
    #updates-widget {
      display: none;
    }

    .navbar-nav {
      .nav-item {
        &.heading {
          display: none;
        }
        .nav-link {
          width: 60px;

          span {
            display: none;
          }
        }
        @include media-breakpoint-up(md) {
          .nav-link {
            text-align: center;

            i.fas,
            i.fab,
            i.far {
              margin-right: 0;
            }
          }
        }
      }
    }

    .submenu-link {
      &::before {
        content: '';
      }
    }
  }

  .wrapper {
    display: flex;
    left: 0;
    transition: transform 0.5s;
    min-height: 100vh;
    z-index: 99;
    position: relative;

    .wrapper-after {
      position: absolute;
      top: 0;
      right: 0;
      width: 0;
      height: 0;
      background: rgba(0, 0, 0, 0.3);
      opacity: 0;
      transition: width 0.3s ease-in-out;
    }
  }

  .rs-wrapper {
    overflow-y: initial;
    width: calc(100% - 60px);
    margin-left: 60px;
    transition: all 0.3s ease-in-out;
  }
}

// Media query for up 1280px screens
@include media-breakpoint-up(xl) {
}
