// // Variables

// // Color system

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #fafafa;
$gray-300: #eeeeee;
$gray-400: #adb5bd;
$gray-500: #999999;
$gray-600: #616160 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #333333 !default;
$black: #333 !default;

$grays: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
  (
    '100': $gray-100,
    '200': $gray-200,
    '300': $gray-300,
    '400': $gray-400,
    '500': $gray-500,
    '600': $gray-600,
    '700': $gray-700,
    '800': $gray-800,
    '900': $gray-900,
  ),
  $grays
);

$blue: #4665e9;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #f25f5c !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #4eb79a;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;

$colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
  (
    'blue': $blue,
    'indigo': $indigo,
    'purple': $purple,
    'pink': $pink,
    'red': $red,
    'orange': $orange,
    'yellow': $yellow,
    'green': $green,
    'teal': $teal,
    'cyan': $cyan,
    'white': $white,
    'gray': $gray-600,
    'gray-dark': $gray-800,
  ),
  $colors
);

$primary: #4665e9;
$secondary: #cbdbfb;
$success: #4eb79a;
$info: $cyan !default;
$warning: $yellow !default;
$danger: #f25f5c;
$light: #fafafa;
$medium-dark: #616160;
$dark: #50514f;
$extra-dark: #454644;
$blue-dark: #2447e5;
$blue-medium: #889ef2;
$blue-light: #cbdbfb;
$error-color: #f25f5c;

$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    'primary': $primary,
    'secondary': $secondary,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'light': $light,
    'dark': $dark,
    'blue-dark': $blue-dark,
    'blue-light': $blue-light,
    'blue-medium': $blue-medium,
  ),
  $theme-colors
);

// // Links
// // Style anchor elements.

$link-color: $blue-dark;

// // Grid breakpoints
// //
// // Define the minimum dimensions at which your layout will change,
// // adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 769px,
  lg: 992px,
  xl: 1280px,
);

// // Grid columns
// // Set the number of columns and specify the width of the gutters.

$grid-gutter-width: 40px;

// // Components
// //
// // Define common padding and border radius sizes and more.

$line-height-sm: 1.5;

$border-width: 1px;

$border-radius: 0.3rem;
$border-radius-lg: 0.4rem;
$border-radius-sm: 0.2rem;

// // Fonts
// //
// // Font, line-height, and color for body text, headings, and more.

// // stylelint-disable value-keyword-case
$font-family-sans-serif: 'lunchtype22regular', sans-serif;
// // stylelint-enable value-keyword-case

$font-size-base: 1rem;
$font-size-lg: ($font-size-base * 1.25) !default;
$font-size-sm: ($font-size-base * 0.875) !default;

$h1-font-size: 22px;
$h2-font-size: 20px;
$h3-font-size: 18px;
$h4-font-size: 16px;
$h5-font-size: 14px;
$text-muted: #808080;

$hr-border-color: rgba($black, 0.08);

// // Tables
// //
// // Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding: 0.8rem 1rem;
$table-cell-padding-sm: 0.5rem 0.75rem;

$table-accent-bg: $gray-200;
$table-hover-bg: rgba($secondary, 0.25);
$table-border-color: transparent;
$table-striped-order: odd;

// // Buttons + Forms
// //
// // Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y: 0.75rem;
$input-btn-padding-x: 1.15rem;

$input-btn-focus-width: 0;
$input-btn-focus-color: $blue-medium;
$input-btn-focus-box-shadow: 0 0 1px $input-btn-focus-width $input-btn-focus-color !default;

$input-btn-padding-y-sm: 0.35rem;
$input-btn-padding-x-sm: 1.15rem;
$input-btn-line-height-sm: $line-height-sm !default;

$input-btn-padding-y-lg: 0.9rem;
$input-btn-border-width: $border-width;

// // Buttons
// //
// // For each of Bootstrap's buttons, define text, background, and border color.

$btn-border-width: $input-btn-border-width;
$btn-focus-box-shadow: $input-btn-focus-box-shadow !default;

// // Allows for customizing button radius independently from global border radius
$btn-border-radius: $border-radius-sm;
$btn-border-radius-lg: $border-radius-sm;

// // Forms

$input-padding-y: $input-btn-padding-y;
$input-padding-x: $input-btn-padding-x;
$input-padding-x-sm: 10px;
$input-color: $gray-900;
$input-border-color: $gray-300;
$input-border-width: $input-btn-border-width;
$input-focus-border-color: $blue-medium;
$input-focus-box-shadow: $input-btn-focus-box-shadow;

$input-placeholder-color: $gray-500;

$input-height-border: $input-border-width * 2;

$input-height-inner: 2.92rem;
$input-height: calc(#{$input-height-inner} + #{$input-height-border});

$input-height-inner-sm: 28px;
$input-height-sm: calc(#{$input-height-inner-sm} + #{$input-height-border});

$form-group-margin-bottom: 1rem;

$input-group-addon-color: $gray-500;
$input-group-addon-bg: $white;

$dropdown-spacer: 0.25rem;

// // Navs

$navbar-toggler-padding-y: 0;
$navbar-toggler-padding-x: 0;
$navbar-toggler-font-size: $font-size-lg !default;
$navbar-toggler-border-radius: none;
$navbar-light-toggler-icon-bg: transparent;
$navbar-light-toggler-border-color: transparent;

// // Pagination

$pagination-padding-y: $input-btn-padding-y;
$pagination-padding-x: 1.5rem;
$pagination-line-height: 1.5;

$pagination-color: $gray-900;
$pagination-bg: $white;
$pagination-border-color: $gray-300;

$pagination-hover-color: $gray-900;
$pagination-hover-bg: $pagination-bg;
$pagination-hover-border-color: darken($pagination-border-color, 10%);
$pagination-active-border-color: $blue-dark;

// // Cards

$card-spacer-y: 1.2rem;
$card-spacer-x: 1.2rem;
$card-border-color: rgba($black, 0.08);
$card-cap-bg: $white;

// // Tooltips

$tooltip-font-size: 1rem;
$tooltip-bg: $gray-900;
$tooltip-border-radius: $border-radius-sm;
$tooltip-opacity: 1;
$tooltip-padding-y: 0.7rem;
$tooltip-padding-x: 0.7rem;
$tooltip-margin: 10px;

// // Popovers

$popover-font-size: $font-size-base;
$popover-border-radius: $border-radius;

$popover-header-bg: $white;
$popover-header-padding-y: 0;
$popover-header-padding-x: 0;

$popover-arrow-width: 40px;
$popover-arrow-height: 25px;

// // Badges

$badge-font-size: 100%;
$badge-font-weight: normal;
$badge-padding-y: 9px;
$badge-padding-x: 12px;

$badge-pill-padding-x: 12px;

// // Modals

// // Padding applied to the modal body
$modal-inner-padding: 1.5rem;

$modal-dialog-margin: 1rem;
$modal-dialog-margin-y-sm-up: 4.7rem;
$modal-backdrop-opacity: 0.3;
$modal-header-border-color: $gray-300;
$modal-header-padding: 1.2rem;
$list-group-border-color: $gray-300;
$close-color: $gray-900;

// // Custom variables
$xs-padding: 10px;
$md-padding: 20px;
$link-color-2: #247ba0;
$border-light: 1px solid $gray-300;
$header-height: 60px;
